import { defineMessages } from 'react-intl';

const messages = defineMessages({
  otherLivingCostPlaceholder: {
    id: 'rahoitu.fi.otherLivingCostPlaceholder',
    defaultMessage: 'E.g. € 500',
    description: 'Placeholder for other living cost',
  },
  coApplicantOtherLivingCostPlaceholder: {
    id: 'rahoitu.fi.coApplicantOtherLivingCostPlaceholder',
    defaultMessage: 'E.g. € 500',
    description: 'Placeholder for other living cost',
  },
  creditCardPlaceholder: {
    id: 'rahoitu.fi.creditCardPlaceholder',
    defaultMessage: 'Choose',
    description: 'Placeholder for asking customer if they have credit cards',
  },
  loanTypeConsumer: {
    id: 'rahoitu.fi.loanTypeConsumer',
    defaultMessage: 'Consumer',
    description: 'Option of loan types in current loans input',
  },
  loanTypeCar: {
    id: 'rahoitu.fi.loanTypeCar',
    defaultMessage: 'Car',
    description: 'Option of loan types in current loans input',
  },
  loanTypeCreditCard: {
    id: 'rahoitu.fi.loanTypeCreditCard',
    defaultMessage: 'CreditCard',
    description: 'Option of loan types in current loans input',
  },
  loanTypeMortgage: {
    id: 'rahoitu.fi.loanTypeMortgage',
    defaultMessage: 'Mortgage',
    description: 'Option of loan types in current loans input',
  },
  loanTypeStudent: {
    id: 'rahoitu.fi.loanTypeStudent',
    defaultMessage: 'Student',
    description: 'Option of loan types in current loans input',
  },
  coApplicantLoanAmountTooltip: {
    id: 'rahoitu.fi.coApplicantLoanAmountTooltip',
    defaultMessage: 'Please provide the co-applicants current outstanding balance of this loan. Do not list any other debts in this field.',
    description: 'tooltip for co-applicants current loan total amount',
  },
  coApplicantLoanMonthlyCostTooltip: {
    id: 'rahoitu.fi.coApplicantLoanMonthlyCostTooltip',
    defaultMessage: 'Please enter the co-applicants monthly installment for this loan. Do not list the costs of any other loans in this field',
    description: 'tooltip for co-applicants current loan total amount',
  },
  loanAmountTooltip: {
    id: 'rahoitu.fi.loanAmountTooltip',
    defaultMessage: 'Please provide the current outstanding balance of this loan. Do not list any other debts in this field.',
    description: 'tooltip for current loan total amount',
  },
  loanMonthlyCostTooltip: {
    id: 'rahoitu.fi.loanMonthlyCostTooltip',
    defaultMessage: 'Please enter the monthly installment for this loan. Do not list the costs of any other loans in this field',
    description: 'tooltip for current loan total amount',
  },
  loansSummary: {
    id: 'rahoitu.fi.loansSummary',
    defaultMessage: 'Total amount to be refinanced',
    description: 'Label for showing sum of refinanced current loans',
  },
  loanAdd: {
    id: 'rahoitu.fi.loanAdd',
    defaultMessage: 'Add loan',
    description: 'Label for adding a current loan',
  },
  loanRemoval: {
    id: 'rahoitu.fi.loanRemoval',
    defaultMessage: 'Remove',
    description: 'Label for removing a current loan',
  },
  loanMonthlyCostLabel: {
    id: 'rahoitu.fi.loanMonthlyCostLabel',
    defaultMessage: 'Monthly cost',
    description: 'Label for current loans Monthly cost',
  },
  loanAmountLabel: {
    id: 'rahoitu.fi.loanAmountLabel',
    defaultMessage: 'Loan amount',
    description: 'Label for current loans Loan amount',
  },
  loanTransferLabel: {
    id: 'rahoitu.fi.loanTransferLabel',
    defaultMessage: 'Would you like to refinance this loan?',
    description: 'Label for current loans transfer toggle',
  },
  loanTypeLabel: {
    id: 'rahoitu.fi.loanTypeLabel',
    defaultMessage: 'Loan type',
    description: 'Label for current loans loan type',
  },
  coApplicantMonthlyIncomeAfterTaxRequired: {
    id: 'rahoitu.fi.errorBoundary.coApplicantMonthlyIncomeAfterTaxRequired',
    defaultMessage: 'Please enter your co-applicants monthly income after tax(€/ mth)',
    description: 'The default error message when co applicants monthly income after tax is missing',
  },
  defaultErrorHeadline: {
    id: 'rahoitu.fi.errorBoundary.defaultErrorHeadline',
    defaultMessage: 'Something went wrong!',
    description: 'The default error message when something has failed - Oops, something went wrong',
  },
  defaultErrorMessage: {
    id: 'rahoitu.fi.errorBoundary.defaultErrorMessage',
    defaultMessage: 'We currently have technical problems. Please contact customer support for assistance or try again!',
    description: 'The default error message when something has failed - Oops, something went wrong',
  },
  defaultErrorMessageWithPhoneNumber: {
    id: 'rahoitu.fi.errorBoundary.defaultErrorMessageWithPhoneNumber',
    defaultMessage: 'We currently have technical problems. Click the button below and we will call you as soon as we can on <i> {customerPhoneNumber} </i> to continue your application',
    description: 'The default error message when something has failed - Oops, something went wrong',
  },
  emailSubmissionInputPlaceholder: {
    id: 'rahoitu.fi.errorBoundary.emailSubmissionInputPlaceholder',
    defaultMessage: 'Your email adress',
    description: 'The default input placeholder for Email Submission form',
  },
  emailSubmissionButtonLabel: {
    id: 'rahoitu.fi.errorBoundary.emailSubmissionButtonLabel',
    defaultMessage: 'Email Submission',
    description: 'The default button label for Email Submission form',
  },
  emailSubmissionSuccessMessage: {
    id: 'rahoitu.fi.errorBoundary.emailSubmissionSuccessMessage',
    defaultMessage: 'Thank you!',
    description: 'Success message on the "Email Submission-button in the form',
  },
  emailSubmissionErrorMessage: {
    id: 'rahoitu.fi.errorBoundary.emailSubmissionErrorMessage',
    defaultMessage: 'We could not send your email adress, please try again later!',
    description: 'Error message on the "Email Submission"-button in the form',
  },
  safeAndSecure: {
    id: 'rahoitu.fi.safeAndSecure',
    defaultMessage: 'Safe and secure',
    description: 'Label for lock seal on the bottom of the form',
  },
  totalLoanUseOnlyNumbers: {
    id: 'rahoitu.fi.totalLoanUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Informing the customer/applicant that only numbers are allowed for the total loan amount',
  },
  tooLowTotalLoan: {
    id: 'rahoitu.fi.tooLowTotalLoan',
    defaultMessage: 'Lowest loan amount is {minAllowedAmount, number, currency}.',
    description: 'Informing the customer/applicant that they have provided a loan amount to low. Also show what the minimum loan amount is',
  },
  tooHighTotalLoan: {
    id: 'rahoitu.fi.tooHighTotalLoan',
    defaultMessage: 'Highest loan amount is {maxAllowedAmount, number, currency}.',
    description: 'Informing the customer/applicant that they have provided a loan amount to high. Also show what the maximum loan amount is',
  },
  tooHighCurrentLoan: {
    id: 'rahoitu.fi.tooHighCurrentLoan',
    defaultMessage: 'Highest loan amount is {maxLimit, number, currency}.',
    description: 'Error message informing the applicant that loan amount is to high',
  },
  textInputIsRequired: {
    id: 'rahoitu.fi.textInputIsRequired',
    defaultMessage: 'This field can not be left empty',
    description: '',
  },
  buttonGroupIsRequired: {
    id: 'rahoitu.fi.buttonGroupIsRequired',
    defaultMessage: 'Please select one.',
    description: 'Tells the customer that one option needs to be selected',
  },
  checkBoxIsRequired: {
    id: 'rahoitu.fi.checkBoxIsRequired',
    defaultMessage: 'Please consent to our terms and conditions',
    description: 'Tells the customer that checkox needs to be checked',
  },
  repaymentTimeUseOnlyNumbers: {
    id: 'rahoitu.fi.repaymentTimeUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Inform the customer that they are only allowed to use numbers',
  },
  tooShortRepaymentTime: {
    id: 'rahoitu.fi.tooShortRepaymentTime',
    defaultMessage: 'Shortest possible repayment time is {loanMinYears} year.',
    description: 'Inform the customer that they have choosen a repayment time shorter than allowed',
  },
  tooLongRepaymentTime: {
    id: 'rahoitu.fi.tooLongRepaymentTime',
    defaultMessage: 'Longest possible repayment time is {loanMaxYears} years.',
    description: 'Inform the customer thath they have choosen a repayment time further ahead than allowed',
  },
  purposeIsRequired: {
    id: 'rahoitu.fi.purposeIsRequired',
    defaultMessage: 'Please choose a loan purpose.',
    description: 'Informing the customer that a cause/reason for the loan must be passed in',
  },
  numCurrentLoansIsRequired: {
    id: 'rahoitu.fi.numCurrentLoansIsRequired',
    defaultMessage: 'Number of current loans are required.',
    description: 'Error message informing the applicant that the number of current loan must be provided',
  },
  numCurrentLoansUseOnlyNumbers: {
    id: 'rahoitu.fi.numCurrentLoansUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Error message informing the applicant that only numbers are allowed',
  },
  tooLowNumCurrentLoans: {
    id: 'rahoitu.fi.tooLowNumCurrentLoans',
    defaultMessage: 'Enter number of credits (0-10).',
    description: 'Error message informing the applicant that the number of current loans provided is to low',
  },
  tooHighNumCurrentLoans: {
    id: 'rahoitu.fi.tooHighNumCurrentLoans',
    defaultMessage: 'Enter number of credits (0-10).',
    description: 'Error message informing the applicant that the number of current loands provided is to high',
  },
  newLoanUseOnlyNumbers: {
    id: 'rahoitu.fi.newLoanUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Inform the customer/applicant that they are only allowed to use numbers',
  },
  tooLowNewLoan: {
    id: 'rahoitu.fi.tooLowNewLoan',
    defaultMessage: 'Lowest loan amount is {minAllowedAmount, number, currency}.',
    description: 'When a customer/applicant has filled in an amount lower than minimum amount allowed',
  },
  tooHighNewLoan: {
    id: 'rahoitu.fi.tooHighNewLoan',
    defaultMessage: 'Highest loan amount is {maxAllowedAmount, number, currency}.',
    description: 'When a customer/applicant has filled in an amount higher than maximum amount allowed',
  },
  mergeLoanUseOnlyNumbers: {
    id: 'rahoitu.fi.mergeLoanUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Error message informing the applicant that only numbers are allowed to be provided',
  },
  tooLowMergeLoan: {
    id: 'rahoitu.fi.tooLowMergeLoan',
    defaultMessage: 'Lowest loan amount is {minAllowedAmount, number, currency}.',
    description: 'Error message informing the applicant that the loan amount is to low and what the minimum requirements are',
  },
  tooHighMergeLoan: {
    id: 'rahoitu.fi.tooHighMergeLoan',
    defaultMessage: 'Highest loan amount is {maxAllowedAmount, number, currency}.',
    description: 'Error message informing the applicant that the loan amount is to high and what the maximum requirements are',
  },
  mergeLoanIsNotABoolean: {
    id: 'rahoitu.fi.mergeLoanIsNotABoolean',
    defaultMessage: 'Please choose if you want to consolidate loans',
    description: 'Error message informing that merge is not of type boolean',
  },
  interestRateUseOnlyNumbers: {
    id: 'rahoitu.fi.interestRateUseOnlyNumbers',
    defaultMessage: 'Please use only numbers',
    description: 'Informing the customer/applicant that only numbers are allowed for the interest rate',
  },
  tooLowInterestRate: {
    id: 'rahoitu.fi.tooLowInterestRate',
    defaultMessage: 'The lowest effective interest rate you can use is {minAllowedAmount} %.',
    description: 'Informing the customer/applicant that they have provided a interest rate to low. Also show what the minimum interest rate is',
  },
  tooHighInterestRate: {
    id: 'rahoitu.fi.tooHighInterestRate',
    defaultMessage: 'The highest effective interest rate you can use is {maxAllowedAmount} %.',
    description: 'Informing the customer/applicant that they have provided a interest rate to high. Also show what the maximum interest rate is',
  },
  interestRateHasTwoDots: {
    id: 'rahoitu.fi.interestRateHasTwoDots',
    defaultMessage: "Please check if you've added too many dots as separators",
    description: 'Informing the customer/applicant that they have...',
  },
  nothingBeforeDotInInterestRate: {
    id: 'rahoitu.fi.nothingBeforeDotInInterestRate',
    defaultMessage: 'Please enter a number before the separator',
    description: 'Informing the customer/applicant that they have...',
  },
  tooManyDecimalsInInterestRate: {
    id: 'rahoitu.fi.tooManyDecimalsInInterestRate',
    defaultMessage: "Please check if you've added too many decimals",
    description: 'Informing the customer/applicant that they have...',
  },
  homeTypeIsRequired: {
    id: 'rahoitu.fi.homeTypeIsRequired',
    defaultMessage: 'Please enter a housing situation.',
    description: 'Error message informing applicant that a type of housing must be provided',
  },
  employmentTypeIsRequired: {
    id: 'rahoitu.fi.employmentTypeIsRequired',
    defaultMessage: 'Please enter an employment type',
    description: 'Inform the customer that a employment type must be provided',
  },
  employedToMonthIsRequired: {
    id: 'rahoitu.fi.employedToMonthIsRequired',
    defaultMessage: 'Month is required.',
    description: 'Error message informing the applicant that an "employed until"-month must be selected',
  },
  employedToRequiredYear: {
    id: 'rahoitu.fi.employedToRequiredYear',
    defaultMessage: 'Year is required.',
    description: 'Error message informing the applicant that a year must be filled in for the field "employed untill the year of"',
  },
  employedToRequiredMonth: {
    id: 'rahoitu.fi.employedToRequiredMonth',
    defaultMessage: 'Month is required.',
    description: 'Error message informing the applicant that a month must be filled in for the field "employed untill the month of',
  },
  employedToUseOnlyNumbers: {
    id: 'rahoitu.fi.employedToUseOnlyNumbers',
    defaultMessage: 'Enter when your project employment ends, only digits (i.e. {nextYear}).',
    description: 'Error message informing the applicant that they need to fill in when their employment ends, only in numbers',
  },
  employedToFormat: {
    id: 'rahoitu.fi.employedToFormat',
    defaultMessage: 'Enter the year with four digits (i.e. {nextYear})',
    description: 'Error message reminding the customer that the year needs to be in the correct format (xxxx)',
  },
  employedToDateIsInThePast: {
    id: 'rahoitu.fi.employedToDateIsInThePast',
    defaultMessage: 'The end date must be a future date.',
    description: 'Error message informing the applicant that the choosen date was set to a date in the past',
  },
  employedToDateTooFarInTheFuture: {
    id: 'rahoitu.fi.employedToDateTooFarInTheFuture',
    defaultMessage: 'The end date is too far in the future',
    description: 'Error message informing the applicant that the choosen date that is more than 100 years in the future',
  },
  employedSinceMonthRequired: {
    id: 'rahoitu.fi.employedSinceMonthRequired',
    defaultMessage: 'Month is required.',
    description: 'Error message informing the applicant that an "employed since"-month must be selected',
  },
  requiredDependants: {
    id: 'rahoitu.fi.requiredDependants',
    defaultMessage: 'Please enter number of children.',
    description: 'Error message informing the applicant that the number of dependants/kids havent been filled in',
  },
  dependantsUseOnlyNumbers: {
    id: 'rahoitu.fi.dependantsUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Error message informing the applicant that they may only use numbers when filling in the number of dependants/kids',
  },
  tooLowDependants: {
    id: 'rahoitu.fi.tooLowDependants',
    defaultMessage: 'Enter number of children.',
    description: 'Error message informing the applicant that they have filled in a too low of an amount of dependants',
  },
  tooHighDependants: {
    id: 'rahoitu.fi.tooHighDependants',
    defaultMessage: 'Enter number of children.',
    description: 'Error message informing the applicant that they have filled in a too high of an amount of dependants',
  },
  consolidateLoanUseOnlyNumber: {
    id: 'rahoitu.fi.consolidateLoanUseOnlyNumber',
    defaultMessage: 'Use numbers please.',
    description: 'Asking the customer/applicant to only use numbers for the input',
  },
  tooHighConsolidateLoan: {
    id: 'rahoitu.fi.tooHighConsolidateLoan',
    defaultMessage: 'Highest loan amount is {maxAllowedAmount, number, currency}.',
    description: 'Informing the customer/applicant that they have entered a loan amount to high. Also shows the max allowed amount',
  },
  requiredCivilStatus: {
    id: 'rahoitu.fi.requiredCivilStatus',
    defaultMessage: 'Please enter a martial status.',
    description: 'Error message informing the applicant that their civil state must be provided',
  },
  phoneNumberMobilePrefixError: {
    id: 'rahoitu.fi.phoneNumberMobilePrefixError',
    defaultMessage: 'Your phone number must begin with 0, +358 or 00358.',
    description: '',
  },
  wrongPhoneNumberNumber: {
    id: 'rahoitu.fi.wrongPhoneNumberNumber',
    defaultMessage: 'The phone number appears to be incorrect. Please enter a valid Finnish mobile phone number.',
  },
  wrongPhoneNumberBeginning: {
    id: 'rahoitu.fi.wrongPhoneNumberBeginning',
    defaultMessage: "The phone number is too long. Verify that you haven't added a digit too many",
    description: 'Error message for when the customer fills in a too long phone number.',
  },
  missingOccupation: {
    id: 'rahoitu.fi.missingOccupation',
    defaultMessage: 'Please enter your occupation',
    description: 'Error message',
  },
  requiredNationalId: {
    id: 'rahoitu.fi.requiredNationalId',
    defaultMessage: 'A national ID is required.',
    description: 'Error message informing the applicant that a social security number/national ID must be provided',
  },
  wrongNationalIdFormat: {
    id: 'rahoitu.fi.wrongNationalIdFormat',
    defaultMessage: 'Wrong format, for example: 131159-2950.',
    description: 'Error message informing the applicant that the social security number/national ID is wrongly formatted',
  },
  tooYoungNationalId: {
    id: 'rahoitu.fi.tooYoungNationalId',
    defaultMessage: 'You have to be at least {minAge} years of age to submit an application.',
    description: 'Error message informing the applicant that the applicant/customer has provided an age to low required to make an application',
  },
  tooOldNationalId: {
    id: 'rahoitu.fi.tooOldNationalId',
    defaultMessage: 'Unfortunately, we can only help people up to {maxAge} years of age.',
    description: 'Error message informing the applicant that the applicant/customer has provided an age to high required to make an application',
  },
  invalidNationalId: {
    id: 'rahoitu.fi.invalidNationalId',
    defaultMessage: 'Invalid national ID.',
    description: 'Error message informing the applicant that the social security number/national ID is invalid',
  },
  employerPhoneMissingPhoneNumber: {
    id: 'rahoitu.fi.employerPhoneMissingPhoneNumber',
    defaultMessage: 'Please enter a phone number',
    description: '',
  },
  requiredBankAccount: {
    id: 'rahoitu.fi.requiredBankAccount',
    defaultMessage: 'Bank account number is required.',
    description: 'Error message informing the applicant that a bank account number must be provided',
  },
  invalidBankAccountFormat: {
    id: 'rahoitu.fi.invalidBankAccountFormat',
    defaultMessage: 'Wrong format.',
    description: 'Error message informing the applicant that the bank account number is wrongly formatted',
  },
  invalidBankAccount: {
    id: 'rahoitu.fi.invalidBankAccount',
    defaultMessage: 'Invalid bank account number',
    description: 'Error message informing the applicant that the bank account number is invalid',
  },
  unemployedSinceDynamicLabel: {
    id: 'rahoitu.fi.unemployedSinceDynamicLabel',
    defaultMessage: 'Unemployed since',
    description: 'Dynamic label for employed since',
  },
  houseLoanAmountTooltip: {
    id: 'rahoitu.fi.houseLoanAmountTooltip',
    defaultMessage: 'Please enter the current amount of debt tied to your house loan/loans. Do not list other debts in this field.',
    description: '',
  },
  studentLoanAmountTooltip: {
    id: 'rahoitu.fi.studentLoanAmountTooltip',
    defaultMessage: 'Please enter the current amount of debt tied to your student loan/loans. Do not list other debts in this field.',
    description: '',
  },
  creditLoanAmountTooltip: {
    id: 'rahoitu.fi.creditLoanAmountTooltip',
    defaultMessage: 'Please enter the current amount of debt tied to your credit card loan/loans. Do not list other debts in this field.',
    description: '',
  },
  privateLoanAmountTooltip: {
    id: 'rahoitu.fi.privateLoanAmountTooltip',
    defaultMessage: 'Please enter the current amount of debt tied to your consumer loan/loans. Do not list other debts in this field.',
    description: '',
  },
  coApplicantPrivateLoansAmountTooltip: {
    id: 'rahoitu.fi.coApplicantPrivateLoansAmountTooltip',
    defaultMessage: 'Please enter the current amount of debt tied to co-applicants consumer loan/loans. Do not list other debts in this field.',
    description: '',
  },
  coApplicantCarLoansAmountTooltip: {
    id: 'rahoitu.fi.coApplicantCarLoansAmountTooltip',
    defaultMessage: 'Please enter the current amount of debt tied to co-applicants car loan/loans. Do not list other debts in this field.',
    description: '',
  },
  coApplicantHouseLoansAmountTooltip: {
    id: 'rahoitu.fi.coApplicantHouseLoansAmountTooltip',
    defaultMessage: 'Please enter the current amount of debt tied to co-applicants house loan/loans. Do not list other debts in this field.',
    description: '',
  },
  coApplicantStudentLoansAmountTooltip: {
    id: 'rahoitu.fi.coApplicantStudentLoansAmountTooltip',
    defaultMessage: 'Please enter the current amount of debt tied to co-applicants student loan/loans. Do not list other debts in this field.',
    description: '',
  },
  coApplicantCreditCardLoansAmountTooltip: {
    id: 'rahoitu.fi.coApplicantCreditCardLoansAmountTooltip',
    defaultMessage: 'Please enter the current amount of debt tied to co-applicants credit card loan/loans. Do not list other debts in this field.',
    description: '',
  },
  houseLoanMonthlyCostTooltip: {
    id: 'rahoitu.fi.houseLoanMonthlyCostTooltip',
    defaultMessage: 'Please enter the monthly cost for your house loan/loans. Do not list other costs in this field.',
    description: '',
  },
  studentLoanMonthlyCostTooltip: {
    id: 'rahoitu.fi.studentLoanMonthlyCostTooltip',
    defaultMessage: 'Please enter the monthly cost for your student loan/loans. Do not list other costs in this field.',
    description: '',
  },
  creditLoanMonthlyCostTooltip: {
    id: 'rahoitu.fi.creditLoanMonthlyCostTooltip',
    defaultMessage: 'Please enter the monthly cost for your credit loan/loans. Do not list other costs in this field.',
    description: '',
  },
  privateLoanMonthlyCostTooltip: {
    id: 'rahoitu.fi.privateLoanMonthlyCostTooltip',
    defaultMessage: 'Please enter the monthly cost for your consumer loan/loans. Do not list other costs in this field.',
    description: '',
  },
  carLoansMonthlyCostTooltip: {
    id: 'rahoitu.fi.carLoansMonthlyCostTooltip',
    defaultMessage: 'Please enter the monthly cost for your car loan/loans. Do not list other costs in this field.',
    description: '',
  },
  coApplicantPrivateLoansMonthlyCostTooltip: {
    id: 'rahoitu.fi.coApplicantPrivateLoansMonthlyCostTooltip',
    defaultMessage: 'Please enter the monthly cost for co-applicants consumer loan/loans. Do not list other costs in this field.',
    description: '',
  },
  coApplicantCarLoansMonthlyCostTooltip: {
    id: 'rahoitu.fi.coApplicantCarLoansMonthlyCostTooltip',
    defaultMessage: 'Please enter the monthly cost for co-applicants car loan/loans. Do not list other costs in this field.',
    description: '',
  },
  coApplicantHouseLoansMonthlyCostTooltip: {
    id: 'rahoitu.fi.coApplicantHouseLoansMonthlyCostTooltip',
    defaultMessage: 'Please enter the monthly cost for co-applicants house loan/loans. Do not list other costs in this field.',
    description: '',
  },
  coApplicantStudentLoansMonthlyCostTooltip: {
    id: 'rahoitu.fi.coApplicantStudentLoansMonthlyCostTooltip',
    defaultMessage: 'Please enter the monthly cost for co-applicants student loan/loans. Do not list other costs in this field.',
    description: '',
  },
  coApplicantCreditCardLoansMonthlyCostTooltip: {
    id: 'rahoitu.fi.coApplicantCreditCardLoansMonthlyCostTooltip',
    defaultMessage: 'Please enter the monthly cost for co-applicants credit loan/loans. Do not list other costs in this field.',
    description: '',
  },
  isPepTooltip: {
    id: 'rahoitu.fi.isPepTooltip',
    defaultMessage: 'Please enter yes if you or anyone close to you are a person in a Politically Exposed Position. PEP is a person who has/had important public functions in a state/international organization. E.g. Head of state, head of government, ministers, deputy or assistant minister or members of parliaments.',
    description: 'Tooltip for politically exposed',
  },
  creditCardTooltip: {
    id: 'rahoitu.fi.creditCardTooltip',
    defaultMessage: 'Do you have any active credit cards?',
    description: 'Tooltip regarding having credit card or not',
  },
  houseLoanTooltip: {
    id: 'rahoitu.fi.houseLoanTooltip',
    defaultMessage: 'Please enter the current debt of your mortgage. House loans/mortgages are loans used for financing the purchase of a property. These are loans that have the property itself as security. The banks and lenders need this information to get an overview of your financial situation and to be able to give out loan offers. Do not specify other debts in this field.',
    description: 'Tooltip regarding filling in current house loans',
  },
  studentLoanTooltip: {
    id: 'rahoitu.fi.studentLoanTooltip',
    defaultMessage: 'Please enter the current debt of your student loan. The banks and lenders need this information to get an overview of your financial situation and to be able to give out loan offers. Do not specify other debts in this field.',
    description: 'Tooltip regarding filling in current student loans',
  },
  carLoanTooltip: {
    id: 'rahoitu.fi.carLoanTooltip',
    defaultMessage: 'Please specify if you have loans to finance purchases of vehicles and have the vehicle itself as security for the loan. The banks and lenders need this information to get an overview of your financial situation and to be able to give out loan offers. Do not specify other debts in this field.',
    description: 'Tooltip regarding filling in current car loans',
  },
  carLoanAmountTooltip: {
    id: 'rahoitu.fi.carLoanAmountTooltip',
    defaultMessage: 'Please enter the current amount of debt tied to your car loan/loans. Do not list other debts in this field.',
    description: 'Tooltip regarding filling in current car loans',
  },
  creditLoanTooltip: {
    id: 'rahoitu.fi.creditLoanTooltip',
    defaultMessage: 'Please enter the current debt of your credit card/cards. The banks and lenders need this information to get an overview of your financial situation and to be able to give out loan offers. Do not specify other debts in this field.',
    description: 'Tooltip regarding filling in current credit loans',
  },
  privateLoanTooltip: {
    id: 'rahoitu.fi.privateLoanTooltip',
    defaultMessage: 'Please enter your current debt for consumer/private loan/loans. Consumer loans are loans without security that can be used for anything - usually for consumption, renovation or paying other expensive debts. The banks and lenders need this information to get an overview of their financial situation and to be able to give out loan offers. Do not specify other debts in this field.',
    description: 'Tooltip regarding filling in current private loans',
  },
  currentLoansInformation: {
    id: 'rahoitu.fi.currentLoansInformation',
    defaultMessage: 'NOTE: Please only list the specific loans we are asking for. Any other loans are not relevant at this stage and might be asked at a later stage if necessary.',
    description: 'Information regarding filling in current loans',
  },
  coApplicantHomeCostTooltip: {
    id: 'rahoitu.fi.coApplicantHomeCostTooltip',
    defaultMessage: 'Please enter the monthly cost connected to your housing. This can consist of rent/fee, heat, water, electricity, insurance etc. If you are several who split the cost, enter your share only (e.g. half the total cost). NOTE: this does not include mortgage/loan costs.',
    description: 'Tooltip for coApplicant home cost field',
  },
  coApplicantHomeTypeTooltip: {
    id: 'rahoitu.fi.coApplicantHomeTypeTooltip',
    defaultMessage: 'If your co-applicant has several types of housing - choose the type of housing in which they are currently registered.',
    description: 'Tooltip for coApplicant home type field',
  },
  coApplicantMonthlyIncomeBeforeTaxTooltip: {
    id: 'rahoitu.fi.coApplicantMonthlyIncomeBeforeTaxTooltip',
    defaultMessage: "Please enter your co-applicant's total monthly income from all sources (€ before taxes). This may include for example salary, commission or pension.",
    description: 'Tooltip for coApplicant income field',
  },
  coApplicantEmailTooltip: {
    id: 'rahoitu.fi.coApplicantEmailTooltip',
    defaultMessage: "We need your co-applicant's email address to contact them regarding the loan application. If the co-applicant does not have an email address, you can contact customer service on 0942456000",
    description: 'Tooltip for coApplicant email field',
  },
  coApplicantPhoneNumberTooltip: {
    id: 'rahoitu.fi.coApplicantPhoneNumberTooltip',
    defaultMessage: "We need your co-applicant's phone number to contact them regarding the loan application.",
    description: 'Tooltip for coApplicant phone number field',
  },
  occupancyTooltip: {
    id: 'rahoitu.fi.occupancyTooltip',
    defaultMessage: 'Please enter your occupation in this field, e.g. a nurse, salesperson, or a cook.',
    description: 'Tooltip for applicant occupation field',
  },
  monthlyIncomeAfterTaxTooltip: {
    id: 'rahoitu.fi.monthlyIncomeAfterTaxTooltip',
    defaultMessage: 'Please enter your total monthly income from all sources (€ after taxes). This may include for example salary, commission or pension. Note that you need to prove your stated income through payment slips and alike.',
    description: 'Tooltip for applicant income field',
  },
  nationalIdTooltip: {
    id: 'rahoitu.fi.nationalIdTooltip',
    defaultMessage: 'We need to verify your social security number to process your application.This needs to be a Finnish social security number.',
    description: 'Tooltip for applicant email field',
  },
  emailTooltip: {
    id: 'rahoitu.fi.emailTooltip',
    defaultMessage: 'We need your email address to contact you regarding the loan application. If you do not have an email address, you can contact customer service on 0942456000',
    description: 'Tooltip for applicant email field',
  },
  phoneNumberTooltip: {
    id: 'rahoitu.fi.phoneNumberTooltip',
    defaultMessage: 'We need your phone number to contact you regarding the loan application.',
    description: 'Tooltip for applicant phone number field',
  },
  businessOrganizationNumberTooltip: {
    id: 'rahoitu.fi.businessOrganizationNumberTooltip',
    defaultMessage: 'Please enter your 7 digit business organization number/business identity code. For e.g. 000000-0. You can find this information from the Tax Administration or from the Finnish Trade Register (PRH)',
    description: 'tooltip for entering business organizational number if self employed',
  },
  businessRegistrationDateMonthTooltip: {
    id: 'rahoitu.fi.businessRegistrationDateMonthTooltip',
    defaultMessage: 'Please enter the month you registered your business',
    description: 'tooltip for entering month of company registration if self employed',
  },
  coApplicantEmployedToTooltip: {
    id: 'rahoitu.fi.coApplicantEmployedToTooltip',
    defaultMessage: 'Please enter when their employment ends.',
    description: 'Tooltip for coApplicant "employed until"-input, placed inside form',
  },
  employedToTooltip: {
    id: 'rahoitu.fi.employedToTooltip',
    defaultMessage: 'Please enter when your employment ends.',
    description: 'Tooltip for "employed until"-input, placed inside form',
  },
  repaymentTimeTooltip: {
    id: 'rahoitu.fi.repaymentTimeTooltip',
    defaultMessage: 'For how long do you want to repay the loan? The longer the period, the lower your monthly cost will be. You can always repay the loan faster without extra costs if you want to.',
    description: 'Tooltip for repayment time inside form, asking the applicant to fill in how long they wish to take to repay their loan. Also informing them that the longer time of repayment, the lower the monthly cost. ',
  },
  addCoApplicantDesc: {
    id: 'rahoitu.fi.addCoApplicantDesc',
    defaultMessage: 'Adding a co-applicant often gives you an increased chance of getting a loan, and often at a better interest rate than without a co-applicant!',
    description: 'Description in add co-applicant textbox',
  },
  addCoApplicantTooltip: {
    id: 'rahoitu.fi.addCoApplicantTooltip',
    defaultMessage: 'Co-applicants can provide the banks with better security as it is deemed a lower risk for the banks when two people apply for the loan. This can give more favorable terms and interest rates for the applicant(s), depending on your financial situation.',
    description: 'Tooltip for adding co-applicant',
  },
  purposeTooltip: {
    id: 'rahoitu.fi.purposeTooltip',
    defaultMessage: 'If you have more than one purpose for the loan, state the main purpose. "Services" can be weddings, parties or cleaning services etc.',
    description: 'Tooltip for The purpose of the loan',
  },
  civilStatusTooltip: {
    id: 'rahoitu.fi.civilStatusTooltip',
    defaultMessage: 'Please enter your relationship status. If you are living separately from your partner, use the option "Single".',
    description: 'Tooltip-text civilstånd i formulär.',
  },
  coApplicantCivilStatusTooltip: {
    id: 'rahoitu.fi.coApplicantCivilStatusTooltip',
    defaultMessage: 'Please enter the relationship status of your co-applicant. If he/she is living separately from their partner, use the option "Single".',
    description: 'Tooltip-text for coApplicant civil status in form.',
  },
  applicationLoanAmountTooltip: {
    id: 'rahoitu.fi.applicationLoanAmountTooltip',
    defaultMessage: 'Please enter the amount you would like to apply for.',
    description: 'Tooltip-text for total loan amount the customer wishes to apply for.',
  },
  currentLoanMergeTooltip: {
    id: 'rahoitu.fi.currentLoanMergeTooltip',
    defaultMessage: 'Our partners can give you a more favorable interest rate if you choose to consolidate your loans. This way, they "buy out" parts or your whole loan situation and therefore merging them into a single loan.',
    description: 'Tooltip-text för val av att lösa lån i formulär.',
  },
  dependantsTooltip: {
    id: 'rahoitu.fi.dependantsTooltip',
    defaultMessage: 'Please enter the number of children living at home who are under 21 years old.',
    description: 'Tooltip-text för antal barn i formulär.',
  },
  coApplicantDependantsTooltip: {
    id: 'rahoitu.fi.coApplicantDependantsTooltip',
    defaultMessage: "Please enter the number of children living at your co-applicant's home who are under 21 years old.",
    description: 'Tooltip-text for coApplicant dependants in form.',
  },
  employerNameTooltip: {
    id: 'rahoitu.fi.employerNameTooltip',
    defaultMessage: 'Please enter the name of the workplace you currently work at. If you have several employers, write the one you consider to be your main one.',
    description: 'Tooltip-text för företagsnamn i formulär.',
  },
  coApplicantEmployerNameTooltip: {
    id: 'rahoitu.fi.coApplicantEmployerNameTooltip',
    defaultMessage: 'Please enter the name of the workplace your co-applicant is currently working for. If they have several employers, write the one that would be considered the main one.',
    description: 'Tooltip-text for coApplicant company name in form.',
  },
  coApplicantEmployerPhoneTooltip: {
    id: 'rahoitu.fi.coApplicantEmployerPhoneTooltip',
    defaultMessage: "Your co-applicant's employer phone number can make it easier for our partners if they need additional information when considering providing a loan proposal.",
    description: 'Tooltip-text for coApplicant employer phone number in form.',
  },
  employmentTypeTooltip: {
    id: 'rahoitu.fi.employmentTypeTooltip',
    defaultMessage: 'Please select your employment type. If you are on leave, choose the employment option from which you got your leave. If you have several jobs, please select the one that you consider as your main one. The choice "Pensioner" also includes early retirees.',
    description: 'Tooltip-text för sysselsättning i formulär.',
  },
  coApplicantEmploymentTypeTooltip: {
    id: 'rahoitu.fi.coApplicantEmploymentTypeTooltip',
    defaultMessage: "Please select your co-applicant's employment type. If they are on leave, choose the employment option from which they got their leave. If they have several jobs, please select the one that is considered the main one. The choice ''Pensioner'' also includes early retirees.",
    description: 'Tooltip-text for coApplicant employment in form.',
  },
  employmentSinceMonthTooltip: {
    id: 'rahoitu.fi.employmentSinceMonthTooltip',
    defaultMessage: "Please select approximately the year that you started at your current job. If you don't know exactly, pick what you think it is.",
    description: 'Tooltip for "employment since"-input',
  },
  employmentSinceYearTooltip: {
    id: 'rahoitu.fi.employmentSinceYearTooltip',
    defaultMessage: 'Please enter approximately the year when you started at your current job. Use 4 numbers, e.g "2002".',
    description: 'Tooltip for "employment since"-input',
  },
  retiredEmploymentSinceTooltip: {
    id: 'rahoitu.fi.retiredEmploymentSinceTooltip',
    defaultMessage: 'Please enter approximately when you retired.',
    description: '',
  },
  coApplicantRetiredEmploymentSinceTooltip: {
    id: 'rahoitu.fi.coApplicantRetiredEmploymentSinceTooltip',
    defaultMessage: 'Please enter approximately when your co-applicant retired.',
    description: 'Tooltip-text for coApplicant retired since in form.',
  },
  homeCostTooltip: {
    id: 'rahoitu.fi.homeCostTooltip',
    defaultMessage: 'Please enter the monthly cost connected to your housing. This can consist of rent/fee, heat, water, electricity, insurance etc. If you are several who split the cost, enter your share only (e.g. half the total cost). NOTE: this does not include mortgage/loan costs.',
    description: 'Tooltip-text för boendekostnad i formulär.',
  },
  homeTypeTooltip: {
    id: 'rahoitu.fi.homeTypeTooltip',
    defaultMessage: 'If you have several addresses, please choose the one where you live today. "Own apartment" is considered bought apartments in housing cooperatives.',
    description: 'Tooltip-text för boendeform i formulär.',
  },
  loanPurposeTooltip: {
    id: 'rahoitu.fi.loanPurposeTooltip',
    defaultMessage: 'Please specify what you want to use the loan for. If the option is not in the list, pick the one that is the closest.',
    description: 'Tooltip-text för lånets syfte i formulär.',
  },

  coApplicantBusinessRegistrationDate: {
    id: 'rahoitu.fi.coApplicantBusinessRegistrationDate',
    defaultMessage: 'Co-applicant Business registration Date (year)',
    description: 'Label for entering year of coApplicant company registration if self employed',
  },
  coApplicantBusinessRegistrationDatePlaceholder: {
    id: 'rahoitu.fi.coApplicantBusinessRegistrationDatePlaceholder',
    defaultMessage: 'Co-applicant Business registration Date (year)',
    description: 'placeholder for entering year of coApplicant company registration if self employed',
  },
  coApplicantBusinessRegistrationDateMonth: {
    id: 'rahoitu.fi.coApplicantBusinessRegistrationDateMonth',
    defaultMessage: 'Co-applicant Business registration Date (month)',
    description: 'Label for entering month of coApplicant company registration if self employed',
  },
  coApplicantBusinessRegistrationDateMonthPlaceholder: {
    id: 'rahoitu.fi.coApplicantBusinessRegistrationDateMonthPlaceholder',
    defaultMessage: 'Co-applicant Business registration Date (month)',
    description: 'placeholder for entering month of coApplicant company registration if self employed',
  },
  businessRegistrationDate: {
    id: 'rahoitu.fi.businessRegistrationDate',
    defaultMessage: 'Business registration Date (year)',
    description: 'Label for entering year of company registration if self employed',
  },
  businessRegistrationDatePlaceholder: {
    id: 'rahoitu.fi.businessRegistrationDatePlaceholder',
    defaultMessage: 'Business registration Date (year)',
    description: 'placeholder for entering year of company registration if self employed',
  },
  businessRegistrationDateMonth: {
    id: 'rahoitu.fi.businessRegistrationDateMonth',
    defaultMessage: 'Business registration Date (month)',
    description: 'Label for entering month of company registration if self employed',
  },
  businessRegistrationDateMonthPlaceholder: {
    id: 'rahoitu.fi.businessRegistrationDateMonthPlaceholder',
    defaultMessage: 'Business registration Date (month)',
    description: 'placeholder for entering month of company registration if self employed',
  },
  monthlyIncomeAfterTaxPlaceholder: {
    id: 'rahoitu.fi.monthlyIncomeAfterTaxPlaceholder',
    defaultMessage: 'X XXX',
    description: 'Placeholder for applicant monthly income after tax inside form',
  },
  monthlyIncomeAfterTaxLabel: {
    id: 'rahoitu.fi.monthlyIncomeAfterTaxLabel',
    defaultMessage: 'Monthly income after taxes (€/mth)',
    description: 'Label for applicant monthly income after tax inside form',
  },
  monthlyIncomeBeforeTaxPlaceholder: {
    id: 'rahoitu.fi.monthlyIncomeBeforeTaxPlaceholder',
    defaultMessage: 'X XXX',
    description: 'Placeholder for applicant monthly income before tax inside form',
  },
  monthlyIncomeBeforeTaxLabel: {
    id: 'rahoitu.fi.monthlyIncomeBeforeTaxLabel',
    defaultMessage: 'Monthly income before taxes (€/mth)',
    description: 'Label for applicant monthly income before tax inside form',
  },
  coApplicantMonthlyIncomeAfterTaxPlaceholder: {
    id: 'rahoitu.fi.coApplicantMonthlyIncomeAfterTaxPlaceholder',
    defaultMessage: 'X XXX',
    description: 'Placeholder for coApplicant monthly income after tax inside form',
  },
  coApplicantMonthlyIncomeAfterTaxLabel: {
    id: 'rahoitu.fi.coApplicantMonthlyIncomeAfterTaxLabel',
    defaultMessage: "Co-applicant's monthly income after taxes (€/mth)",
    description: 'Label for coApplicant monthly income after tax inside form',
  },
  coApplicantMonthlyIncomeBeforeTaxPlaceholder: {
    id: 'rahoitu.fi.coApplicantMonthlyIncomeBeforeTaxPlaceholder',
    defaultMessage: 'X XXX',
    description: 'Placeholder for coApplicant monthly income before tax inside form',
  },
  coApplicantMonthlyIncomeBeforeTaxLabel: {
    id: 'rahoitu.fi.coApplicantMonthlyIncomeBeforeTaxLabel',
    defaultMessage: 'Co-applicant’s monthly income before taxes (€/mth)',
    description: 'Label for coApplicant monthly income before tax inside form',
  },
  coApplicantEmployedToYearPlaceholder: {
    id: 'rahoitu.fi.coApplicantEmployedToYearPlaceholder',
    defaultMessage: 'Year',
    description: 'Default text for coApplicant "employed until"-input in years, placed inside form',
  },
  coApplicantEmployedToYearLabel: {
    id: 'rahoitu.fi.coApplicantEmployedToYearLabel',
    defaultMessage: 'Co-applicant employed until (year)',
    description: 'Label for coApplicant "employed until"-input in years, placed inside form',
  },
  coApplicantEmployedToMonthLabel: {
    id: 'rahoitu.fi.coApplicantEmployedToMonthLabel',
    defaultMessage: 'Co-applicant employed until (month)',
    description: 'Default text for coApplicant "employed until"-input in months, placed inside form',
  },
  coApplicantEmployedToMonthPlaceholder: {
    id: 'rahoitu.fi.coApplicantEmployedToMonthPlaceholder',
    defaultMessage: 'Month',
    description: 'Default text for coApplicant "employed until"-input in months, placed inside form',
  },
  employedToMonthLabel: {
    id: 'rahoitu.fi.employedToMonthLabel',
    defaultMessage: 'Employed until (month)',
    description: 'Default text for "employed until"-input in months, placed inside form',
  },
  employedToYearPlaceholder: {
    id: 'rahoitu.fi.employedToYearPlaceholder',
    defaultMessage: 'Year',
    description: 'Default text for "employed until"-input in years, placed inside form',
  },
  employedToYearLabel: {
    id: 'rahoitu.fi.employedToYearLabel',
    defaultMessage: 'Employed until (year)',
    description: 'Label for "employed until"-input in years, placed inside form',
  },
  employedToMonthPlaceholder: {
    id: 'rahoitu.fi.employedToMonthPlaceholder',
    defaultMessage: 'Month',
    description: 'Default text for "employed until"-input in months, placed inside form',
  },
  retiredSinceDynamicLabel: {
    id: 'rahoitu.fi.retiredSinceDynamicLabel',
    defaultMessage: 'Retired since',
    description: 'Dynamic label for employedSinceYear',
  },
  disabilityPensionSinceDynamicLabel: {
    id: 'rahoitu.fi.disabilityPensionSinceDynamicLabel',
    defaultMessage: 'Disability pensioner since',
    description: 'Dynamic label for employedSinceYear',
  },
  employedSinceMonthplaceholder: {
    id: 'rahoitu.fi.employedSinceMonthplaceholder',
    defaultMessage: 'Month',
    description: 'Default text for "employed since"-input in months, placed inside form',
  },
  nextSlide: {
    id: 'rahoitu.fi.nextSlide',
    defaultMessage: 'Next',
    description: 'general progress button label',
  },
  slideTitleOne: {
    id: 'rahoitu.fi.slideTitleOne',
    defaultMessage: 'Your details',
    description: 'First slide title',
  },
  slideTitleTwo: {
    id: 'rahoitu.fi.slideTitleTwo',
    defaultMessage: 'Employment',
    description: '',
  },
  slideTitleThree: {
    id: 'rahoitu.fi.slideTitleThree',
    defaultMessage: 'Housing',
    description: '',
  },
  slideTitleFour: {
    id: 'rahoitu.fi.slideTitleFour',
    defaultMessage: 'Loans',
    description: '',
  },
  slideTitleFive: {
    id: 'rahoitu.fi.slideTitleFive',
    defaultMessage: "Co-applicant's details",
    description: '',
  },
  spouseMonthlyIncomeLabel: {
    id: 'rahoitu.fi.spouseMonthlyIncomeLabel',
    defaultMessage: "Spouse's monthly income before taxes (€/mth)",
    description: 'Label for spouse monthly income field',
  },
  spouseMonthlyIncomePlaceholder: {
    id: 'rahoitu.fi.spouseMonthlyIncomePlaceholder',
    defaultMessage: "Spouse's monthly income before taxes (€/mth)",
    description: 'Placeholder for spouse monthly income field',
  },
  spouseMonthlySalaryBeforeTaxRequired: {
    id: 'rahoitu.fi.spouseMonthlySalaryBeforeTaxRequired',
    defaultMessage: "Spouse's monthly income before tax is required",
    description: 'Error message for not filling in spouse monthly income',
  },
  spouseMonthlySalaryOnlynumbers: {
    id: 'rahoitu.fi.spouseMonthlySalaryOnlynumbers',
    defaultMessage: 'Please write your spouse income in numbers only',
    description: 'Error message for only allowed to use numbers',
  },
  spouseMonthlySalaryMustBePositive: {
    id: 'rahoitu.fi.spouseMonthlySalaryMustBePositive',
    defaultMessage: "Your spouse's monthly income can not be negative",
    description: 'Error message for only allowed to use numbers',
  },
  repaymentTimePlaceholder: {
    id: 'rahoitu.fi.repaymentTimePlaceholder',
    defaultMessage: '0',
    description: 'Default text for repayment time inside form',
  },
  repaymentTimeLabel: {
    id: 'rahoitu.fi.repaymentTimeLabel',
    defaultMessage: 'Repayment time',
    description: 'Label for repayment time inside form',
  },
  repaymentTimeSuffix: {
    id: 'rahoitu.fi.repaymentTimeSuffix',
    defaultMessage: 'years',
    description: 'Suffix for repayment time inside form',
  },
  addCoApplicantLabel: {
    id: 'rahoitu.fi.addCoApplicantLabel',
    defaultMessage: 'Do you want to increase your chances of receiving more favorable offers by adding a co-applicant?',
    description: 'Experiment-variant for add co-applicant option that the applicant can use if they wish to share their loan with a co-applicant',
  },
  purposeHeaderText: {
    id: 'rahoitu.fi.purposeHeaderText',
    defaultMessage: 'Purpose of the credit',
    description: 'Header in the application form for the group of loan purpose related inputs - The purpose of the loan',
  },
  civilStatusPlaceholder: {
    id: 'rahoitu.fi.civilStatusPlaceholder',
    defaultMessage: 'Please enter marital status',
    description: 'Default-text för civilstånd i formulär.',
  },
  civilStatusLabel: {
    id: 'rahoitu.fi.civilStatusLabel',
    defaultMessage: 'Marital status',
    description: 'Label-text för civilstånd i formulär.',
  },
  civilStatusSingle: {
    id: 'rahoitu.fi.civilStatusSingle',
    defaultMessage: 'Single',
    description: 'Marital status of the applicant - single',
  },
  civilStatusPartner: {
    id: 'rahoitu.fi.civilStatusPartner',
    defaultMessage: 'Cohabitant',
    description: 'Marital status of the applicant - partner',
  },
  civilStatusMarried: {
    id: 'rahoitu.fi.civilStatusMarried',
    defaultMessage: 'Married',
    description: 'Marital status of the applicant - married',
  },
  coApplicantCivilStatusLabel: {
    id: 'rahoitu.fi.coApplicantCivilStatusLabel',
    defaultMessage: 'Marital status of co-applicant',
    description: 'Label for civil status of co-applicant',
  },
  applicationLoanAmountPlaceholder: {
    id: 'rahoitu.fi.applicationLoanAmountPlaceholder',
    defaultMessage: 'X XXX',
    description: 'Default-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  applicationLoanAmountLabel: {
    id: 'rahoitu.fi.applicationLoanAmountLabel',
    defaultMessage: 'Loan amount',
    description: 'Label-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  applicationLoanAmountSuffix: {
    id: 'rahoitu.fi.applicationLoanAmountSuffix',
    defaultMessage: '€',
    description: 'Suffix-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  currentLoanMergeLabel: {
    id: 'rahoitu.fi.currentLoanMergeLabel',
    defaultMessage: 'I want to consolidate loans',
    description: 'Label-text för val av att lösa lån i formulär.',
  },
  otherIncomeLabel: {
    id: 'rahoitu.fi.otherIncomeLabel',
    defaultMessage: 'Do you have any other income?',
    description: 'Label-text for asking the customer if they have any other income',
  },
  otherIncomeTooltip: {
    id: 'rahoitu.fi.otherIncomeTooltip',
    defaultMessage: 'Do you have additional sources of income that are not stated already? This may include for example income from letting an apartment, income from a hobby, or state allowance. You need to prove your additional income through tax or payment slips',
    description: 'Tooltip-text for asking the customer if they have any other income',
  },
  dependantsLabel: {
    id: 'rahoitu.fi.dependantsLabel',
    defaultMessage: 'Number of children under 21',
    description: 'Label-text för antal barn i formulär.',
  },
  dependantsZero: {
    id: 'rahoitu.fi.dependantsZero',
    defaultMessage: '0',
    description: 'No child',
  },
  dependantsOne: {
    id: 'rahoitu.fi.dependantsOne',
    defaultMessage: '1',
    description: 'One child',
  },
  dependantsTwo: {
    id: 'rahoitu.fi.dependantsTwo',
    defaultMessage: '2',
    description: 'Two children',
  },
  dependantsThree: {
    id: 'rahoitu.fi.dependantsThree',
    defaultMessage: '3',
    description: 'Three children',
  },
  dependantsFour: {
    id: 'rahoitu.fi.dependantsFour',
    defaultMessage: '4',
    description: 'Four children',
  },
  dependantsFive: {
    id: 'rahoitu.fi.dependantsFive',
    defaultMessage: '5+',
    description: 'Five children',
  },
  coApplicantDependantsLabel: {
    id: 'rahoitu.fi.coApplicantDependantsLabel',
    defaultMessage: 'Number of children under 21',
    description: 'Label text for number of children',
  },
  emailPlaceholder: {
    id: 'rahoitu.fi.emailPlaceholder',
    defaultMessage: 'name@email.com',
    description: 'Default-text för e-post i formulär.',
  },
  emailLabel: {
    id: 'rahoitu.fi.emailLabel',
    defaultMessage: 'Email',
    description: 'Label-text för e-post i formulär.',
  },
  emailSuggestion: {
    id: 'rahoitu.fi.emailSuggestion',
    defaultMessage: 'Did you mean',
    description: 'The pre-suggestion help text at the email input field.',
  },
  emailMustBeUnique: {
    id: 'rahoitu.fi.emailMustBeUnique',
    defaultMessage: 'The main applicant and the co-applicant cannot have the same email.',
    description: 'Informing the customer that the applicant and co-applicant can not use the same email',
  },
  coApplicantEmailLabel: {
    id: 'rahoitu.fi.coApplicantEmailLabel',
    defaultMessage: 'Co-applicant’s email',
    description: 'Label-text för e-post i formulär.',
  },
  coApplicantEmailPlaceholder: {
    id: 'rahoitu.fi.coApplicantEmailPlaceholder',
    defaultMessage: 'name@email.com',
    description: 'Default-text for coApplicant email in form.',
  },
  emailRequired: {
    id: 'rahoitu.fi.emailRequired',
    defaultMessage: 'Please enter your email',
    description: 'Error message informing the applicant that an email address must be provided',
  },
  emailNoSpaces: {
    id: 'rahoitu.fi.emailNoSpaces',
    defaultMessage: 'The email cannot contain blank spaces',
    description: 'Error message informing the applicant that the provided email address must not contain any spaces',
  },
  emailNoAt: {
    id: 'rahoitu.fi.emailNoAt',
    defaultMessage: 'A valid email needs to include @',
    description: 'Error message informing the applicant that the provided email address must contain the symbol "@"',
  },
  emailTooManyAts: {
    id: 'rahoitu.fi.emailTooManyAts',
    defaultMessage: 'The email cannot contain more than one @.',
    description: 'Error message informing the applicant that the provided email address may not contain more than one "@"',
  },
  emailNoName: {
    id: 'rahoitu.fi.emailNoName',
    defaultMessage: 'A valid email needs something before the @',
    description: 'Error message informing the applicant that the email address must contain a name in from of the email address',
  },
  emailDotBeforeAtSign: {
    id: 'rahoitu.fi.emailDotBeforeAtSign',
    defaultMessage: 'The email domain cannot contain a dot (.) before @.',
    description: 'Error message informing the applicant that the domain of the email address can not contain two dots after each other',
  },
  emailTwoDots: {
    id: 'rahoitu.fi.emailTwoDots',
    defaultMessage: 'The email cannot contain two dots in a row.',
    description: 'Error message informing the applicant that the domain of the email address can not contain two dots after each other',
  },
  emailNoDomain: {
    id: 'rahoitu.fi.emailNoDomain',
    defaultMessage: 'A valid email needs to end with .fi/.com/.net or alike',
    description: 'Error message informing the applicant that the email address provided must end with a domain, for example ".com", ".fi" or ".nu"',
  },
  emailNoTopLevelDomain: {
    id: 'rahoitu.fi.emailNoTopLevelDomain',
    defaultMessage: 'A valid email needs a web address after @',
    description: 'Error message informing the applicant that the email address provided must contain a domain name after the "@"',
  },
  coApplicantEmailRequired: {
    id: 'rahoitu.fi.coApplicantEmailRequired',
    defaultMessage: "Please enter your co-applicant's email",
    description: 'Error message informing the applicant that an email address must be provided',
  },
  employerNamePlaceholder: {
    id: 'rahoitu.fi.employerNamePlaceholder',
    defaultMessage: 'Employer name',
    description: 'Default-text för arbetsgivarnamn i formulär.',
  },
  employerNameLabel: {
    id: 'rahoitu.fi.employerNameLabel',
    defaultMessage: 'Employer',
    description: 'Label-text för företagsnamn i formulär.',
  },
  employerNameRequired: {
    id: 'rahoitu.fi.employerNameRequired',
    defaultMessage: 'Please enter the name of your employer',
    description: 'Error message informing the applicant that an employer must be provided',
  },
  coApplicantEmployerNamePlaceholder: {
    id: 'rahoitu.fi.coApplicantEmployerNamePlaceholder',
    defaultMessage: 'Employer name',
    description: 'Default text employer name',
  },
  coApplicantEmployerNameLabel: {
    id: 'rahoitu.fi.coApplicantEmployerNameLabel',
    defaultMessage: 'Employer of co-applicant',
    description: 'Label text for employer name',
  },
  coApplicantEmployerNameRequired: {
    id: 'rahoitu.fi.coApplicantEmployerNameRequired',
    defaultMessage: "Please enter the name of your co-applicant's employer",
    description: 'Error message informing the applicant that an employer must be provided',
  },
  employerPhonePlaceholder: {
    id: 'rahoitu.fi.employerPhonePlaceholder',
    defaultMessage: 'Employer phone number',
    description: 'Default-text för arbetsgivarens telefonnummer i formulär.',
  },
  employerPhoneLabel: {
    id: 'rahoitu.fi.employerPhoneLabel',
    defaultMessage: 'Employer phone number',
    description: 'Label-text för arbetsgivarens telefonnummer i formulär.',
  },
  employerPhoneTooltip: {
    id: 'rahoitu.fi.employerPhoneTooltip',
    defaultMessage: 'Your employer telephone number can make it easier for our partners if they need additional information when considering giving out a loan proposal',
    description: 'Tooltip-text för arbetsgivarens telefonnummer i formulär.',
  },
  employerPhoneRequired: {
    id: 'rahoitu.fi.employerPhoneRequired',
    defaultMessage: "Please enter your employer's phone number",
    description: 'Error message for when the customer hasnt filled in a phone number to their employer',
  },
  coApplicantEmployerPhoneRequired: {
    id: 'rahoitu.fi.coApplicantEmployerPhoneRequired',
    defaultMessage: "Please enter their employer's phone number",
    description: 'Error message for when the customer hasnt filled in a phone number to their coapplicants employer',
  },
  employerPhoneWrongNumber: {
    id: 'rahoitu.fi.employerPhoneWrongNumber',
    defaultMessage: 'The phone number appears to be incorrect. Please enter a valid number',
    description: 'Error message for when the customer fills in a non-valid input for the number to their employer.',
  },
  employerPhoneWrongBeginning: {
    id: 'rahoitu.fi.employerPhoneWrongBeginning',
    defaultMessage: 'The number needs to start with 0, +358 or 00358.',
    description: 'Error message for when the customer fills in a foreign phone number to their employer.',
  },
  employerPhoneTooShort: {
    id: 'rahoitu.fi.employerPhoneTooShort',
    defaultMessage: "The phone number is too short. Verify that you haven't forgotten a digit",
    description: 'Error message for when the customer fills in a too short phone number to their employer.',
  },
  employerPhoneTooLong: {
    id: 'rahoitu.fi.employerPhoneTooLong',
    defaultMessage: "The phone number is too long. Verify that you haven't added too many digits.",
    description: 'Error message for when the customer fills in a too long phone number to their employer.',
  },
  coApplicantEmployerPhonePlaceholder: {
    id: 'rahoitu.fi.coApplicantEmployerPhonePlaceholder',
    defaultMessage: 'Employer phone number',
    description: 'placeholder text for coApplicant employer phone number in form.',
  },
  coApplicantEmployerPhoneLabel: {
    id: 'rahoitu.fi.coApplicantEmployerPhoneLabel',
    defaultMessage: 'Co-applicant’s employer phone number',
    description: 'label text for coApplicant employer phone number in form.',
  },
  employmentTypeLabel: {
    id: 'rahoitu.fi.employmentTypeLabel',
    defaultMessage: 'Employment',
    description: 'Label-text för sysselsättning i formulär.',
  },
  employmentTypePermanent: {
    id: 'rahoitu.fi.employmentTypePermanent',
    defaultMessage: 'Permanent',
    description: 'Employment type - Tenured/employed indefinitely/until further notice',
  },
  employmentTypeTemporary: {
    id: 'rahoitu.fi.employmentTypeTemporary',
    defaultMessage: 'Temporary',
    description: 'Employment type - Temporary',
  },
  employmentTypePartTime: {
    id: 'rahoitu.fi.employmentTypePartTime',
    defaultMessage: 'Part time',
    description: 'Employment type - Part time',
  },
  employmentTypeRetired: {
    id: 'rahoitu.fi.employmentTypeRetired',
    defaultMessage: 'Pensioner',
    description: 'Employment type - Retired/Early retirement',
  },
  employmentTypeParentalLeave: {
    id: 'rahoitu.fi.employmentTypeParentalLeave',
    defaultMessage: 'Parental leave',
    description: 'Employment type - Parental leave',
  },
  employmentTypeStudent: {
    id: 'rahoitu.fi.employmentTypeStudent',
    defaultMessage: 'Student',
    description: 'Employment type - Student',
  },
  employmentTypeUnemployed: {
    id: 'rahoitu.fi.employmentTypeUnemployed',
    defaultMessage: 'Unemployed',
    description: 'Employment type - Unemployed',
  },
  employmentTypeDisabilityPension: {
    id: 'rahoitu.fi.employmentTypeDisabilityPension',
    defaultMessage: 'Disability pension',
    description: 'Employment type - Retired/pensioner on disability',
  },
  employmentTypeRehabilitation: {
    id: 'rahoitu.fi.employmentTypeRehabilitation',
    defaultMessage: 'Rehabilitation',
    description: 'Employment type - rehabilitation',
  },
  employmentTypeSelfEmployed: {
    id: 'rahoitu.fi.employmentTypeSelfEmployed',
    defaultMessage: 'Self employed',
    description: 'Employment type - Self employed',
  },
  employmentTypeOther: {
    id: 'rahoitu.fi.employmentTypeOther',
    defaultMessage: 'Other',
    description: 'Employment type - other',
  },
  employmentTypeByTheHour: {
    id: 'rahoitu.fi.employmentTypeByTheHour',
    defaultMessage: 'Hourly wage',
    description: 'Employment type - Extra work/hourly contract',
  },
  employmentTypeEmployedAtOwnCompany: {
    id: 'rahoitu.fi.employmentTypeEmployedAtOwnCompany',
    defaultMessage: 'Own business',
    description: 'Employment type - Has his/her own business',
  },
  coApplicantEmploymentTypeLabel: {
    id: 'rahoitu.fi.coApplicantEmploymentTypeLabel',
    defaultMessage: 'Employment of co-applicant',
    description: 'Label-text för sysselsättnig i formulär.',
  },
  employmentSinceMonthPlaceholder: {
    id: 'rahoitu.fi.employmentSinceMonthPlaceholder',
    defaultMessage: 'Month',
    description: 'Default text for "employment since"-input in months, placed inside form',
  },
  employmentSinceYearPlaceholder: {
    id: 'rahoitu.fi.employmentSinceYearPlaceholder',
    defaultMessage: 'Year',
    description: 'Default text for "employment since"-input in years, placed inside form',
  },
  employmentSinceYearLabel: {
    id: 'rahoitu.fi.employmentSinceYearLabel',
    defaultMessage: 'Employed since (year)',
    description: 'Label for "employment since"-input in years, placed inside form',
  },
  employmentSinceMonthLabel: {
    id: 'rahoitu.fi.employmentSinceMonthLabel',
    defaultMessage: 'Employed since (month)',
    description: 'Label for "employment since"-input in months, placed inside form',
  },
  employedSinceMonthPlaceholder: {
    id: 'rahoitu.fi.employedSinceMonthPlaceholder',
    defaultMessage: 'Month',
    description: 'Default text for "employment since"-input in months, placed inside form',
  },
  retiredEmploymentSinceLabel: {
    id: 'rahoitu.fi.retiredEmploymentSinceLabel',
    defaultMessage: 'Retired since',
    description: '',
  },
  employedSinceRequiredYear: {
    id: 'rahoitu.fi.employedSinceRequiredYear',
    defaultMessage: 'Please enter the year you started your current employment',
    description: 'Error message informing the applicant that they must pass in which year their employment started',
  },
  coAppEmployedSinceRequiredYear: {
    id: 'rahoitu.fi.coApplicant.employedSinceRequiredYear',
    defaultMessage: 'Please enter the year your co-applicant started their current employment',
    description: 'Error message informing the coapplicant that they must pass in which year their employment started',
  },
  employedSinceRequiredMonth: {
    id: 'rahoitu.fi.employedSinceRequiredMonth',
    defaultMessage: 'Please enter the month you started your current employment',
    description: 'Error message informing the applicant that they must pass in which month their employment started',
  },
  coAppEmployedSinceRequiredMonth: {
    id: 'rahoitu.fi.coApplicant.employedSinceRequiredMonth',
    defaultMessage: 'Please enter the month your co-applicant started their current employment',
    description: 'Error message informing the coapplicant that they must pass in which month their employment started',
  },
  employedSinceOnlyNumbers: {
    id: 'rahoitu.fi.employedSinceOnlyNumbers',
    defaultMessage: 'Please enter the year in digits (e.g. 2010).',
    description: 'Error message informing the applicant that they may only use digits when filling in which year their employment started',
  },
  employedSinceFourDigits: {
    id: 'rahoitu.fi.employedSinceFourDigits',
    defaultMessage: 'Please write the year in 4 digits, e.g. 2010.',
    description: 'Error message informing the applicant that the format for "year their employment started" should be filled in with four digits',
  },
  employedSinceTooFarBack: {
    id: 'rahoitu.fi.employedSinceTooFarBack',
    defaultMessage: 'Enter the year you started (e.g. 2010).',
    description: 'Error message informing the applicant that the year their employment started is set to far back in time',
  },
  coApplicantEmployedSinceTooFarBack: {
    id: 'rahoitu.fi.coApplicant.employedSinceTooFarBack',
    defaultMessage: 'Enter the year they started (e.g. 2010).',
    description: 'Error message informing the coapplicant started their employment to far back in time',
  },
  employedSinceStartTimeInTheFuture: {
    id: 'rahoitu.fi.employedSinceStartTimeInTheFuture',
    defaultMessage: 'Your employment date cannot be in the future',
    description: 'Error message informing the applicant that their "start time of the employment" cannot be set to a date in the future',
  },
  coAppEmployedSinceStartTimeInTheFuture: {
    id: 'rahoitu.fi.employedSinceCoApplicant.startTimeInTheFuture',
    defaultMessage: 'Their employment date cannot be in the future.',
    description: 'Error message informing the coapplicant that their "start time of the employment" cannot be set to a date in the future',
  },
  employedSinceStartTimeInTheFutureMonth: {
    id: 'rahoitu.fi.employedSinceStartTimeInTheFutureMonth',
    defaultMessage: 'The employment date cannot be in the future',
    description: 'Error message informing the start date is in the future',
  },
  homeCostPlaceholder: {
    id: 'rahoitu.fi.homeCostPlaceholder',
    defaultMessage: 'E.g. € 500',
    description: 'Default-text för boendekostnad i formulär.',
  },
  homeCostLabel: {
    id: 'rahoitu.fi.homeCostLabel',
    defaultMessage: 'Housing cost (€/mth)',
    description: 'Label-text för boendekostnad i formulär.',
  },
  homeCostRequired: {
    id: 'rahoitu.fi.homeCostRequired',
    defaultMessage: 'Please enter your monthly housing cost',
    description: 'Inform the customer that they must pass in a monthly cost for their current home-situation (ex: rent)',
  },
  homeCostOnlyDigits: {
    id: 'rahoitu.fi.homeCostOnlyDigits',
    defaultMessage: 'Please write your housing cost in numbers only',
    description: 'Inform the customer that they may only pass in digits in the input for the monthly cost of their home-situation',
  },
  homeCostTooHigh: {
    id: 'rahoitu.fi.homeCostTooHigh',
    defaultMessage: 'Your housing cost cannot be more than {maxCost, number} €/mth for you to apply',
    description: 'Inform the customer that we may unfortunately only accept a monthly cost of "x" and that their cost is to high',
  },
  homeCostTooLow: {
    id: 'rahoitu.fi.homeCostTooLow',
    defaultMessage: 'Enter monthly cost for your accommodation.',
    description: 'Inform the customer that their passed in monthly cost for their home-situation is set to low, for example "0"',
  },
  coApplicantHomeCostPlaceholder: {
    id: 'rahoitu.fi.coApplicantHomeCostPlaceholder',
    defaultMessage: 'E.g. € 500',
    description: 'Default-text för boendekostnad i formulär.',
  },
  coApplicantHomeCostLabel: {
    id: 'rahoitu.fi.coApplicantHomeCostLabel',
    defaultMessage: "Co-applicant's housing cost (€/mth)",
    description: 'Label-text för boendekostnad i formulär.',
  },
  coApplicantHomeCostRequired: {
    id: 'rahoitu.fi.coApplicantHomeCostRequired',
    defaultMessage: "Please enter your co-applicant's monthly housing cost",
    description: 'Inform the customer that they must pass in a monthly cost for their current home-situation (ex: rent)',
  },
  coApplicantHomeCostOnlyDigits: {
    id: 'rahoitu.fi.coApplicantHomeCostOnlyDigits',
    defaultMessage: 'Please write the housing cost in numbers only',
    description: 'Inform the customer that they may only pass in digits in the input for the monthly cost of their home-situation',
  },
  coApplicantHomeCostTooHigh: {
    id: 'rahoitu.fi.coApplicantHomeCostTooHigh',
    defaultMessage: 'Their housing cost cannot be more than {maxCost, number} €/mth for you to apply',
    description: 'Inform the customer that we may unfortunately only accept a monthly cost of "x" and that their cost is to high',
  },
  coApplicantHomeCostTooLow: {
    id: 'rahoitu.fi.coApplicantHomeCostTooLow',
    defaultMessage: 'Enter the monthly cost for their accommodation.',
    description: 'Inform the customer that their passed in monthly cost for their home-situation is set to low, for example "0"',
  },
  homeTypeLabel: {
    id: 'rahoitu.fi.homeTypeLabel',
    defaultMessage: 'Housing',
    description: 'Label-text för boendeform i formulär.',
  },
  homeTypeOther: {
    id: 'rahoitu.fi.homeTypeOther',
    defaultMessage: 'Other',
    description: 'Residential type - other',
  },
  homeTypeRentedApartment: {
    id: 'rahoitu.fi.homeTypeRentedApartment',
    defaultMessage: 'Rented apartment',
    description: 'Residential type - Rented apartment',
  },
  homeTypeVilla: {
    id: 'rahoitu.fi.homeTypeVilla',
    defaultMessage: 'Villa',
    description: 'Residential type - Villa',
  },
  homeTypeCondominium: {
    id: 'rahoitu.fi.homeTypeCondominium',
    defaultMessage: 'Condominium',
    description: 'Residential type - Condominium',
  },
  homeTypeParents: {
    id: 'rahoitu.fi.homeTypeParents',
    defaultMessage: 'Parents',
    description: 'Residential type - Parents',
  },
  homeTypeCategorieOther: {
    id: 'rahoitu.fi.homeTypeCategorieOther',
    defaultMessage: 'Other',
    description: 'Residential category type - Other',
  },
  homeTypeCategorieTerraced: {
    id: 'rahoitu.fi.homeTypeCategorieTerraced',
    defaultMessage: 'Terraced house',
    description: 'Residential category type - Terraced house',
  },
  homeTypeCategorieDetached: {
    id: 'rahoitu.fi.homeTypeCategorieDetached',
    defaultMessage: 'Detached house',
    description: 'Residential category type - Detached house',
  },
  homeTypeCategorieTownHouse: {
    id: 'rahoitu.fi.homeTypeCategorieTownHouse',
    defaultMessage: 'Town house',
    description: 'Residential category type - Town house',
  },
  homeTypeCategorieBlock: {
    id: 'rahoitu.fi.homeTypeCategorieBlock',
    defaultMessage: 'Block of flats',
    description: 'Residential category type - Block',
  },
  homeTypeCategoryDescriptionLabel: {
    id: 'rahoitu.fi.homeTypeCategoryDescriptionLabel',
    defaultMessage: 'Type of apartment',
    description: 'Label for the type of apartment field',
  },
  coApplicantHomeTypeLabel: {
    id: 'rahoitu.fi.coApplicantHomeTypeLabel',
    defaultMessage: 'Housing of co-applicant',
    description: 'Label-text för boendeform i formulär.',
  },
  coApplicantHomeTypeCategoryDescriptionLabel: {
    id: 'rahoitu.fi.coApplicantHomeTypeCategoryDescriptionLabel',
    defaultMessage: 'Type of apartment',
    description: 'Label for the type of apartment field',
  },
  loanPurposeRequired: {
    id: 'rahoitu.fi.loanPurposeRequired',
    defaultMessage: 'The loan purpose is required.',
    description: '',
  },
  loanPurposeConsolidateLoans: {
    id: 'rahoitu.fi.loanPurposeConsolidateLoans',
    defaultMessage: 'Consolidate loans',
    description: 'Purpose of the loan - Consolidate loans',
  },
  loanPurposeVehicles: {
    id: 'rahoitu.fi.loanPurposeVehicles',
    defaultMessage: 'Vehicle',
    description: 'Purpose of the loan - Vehicle expenses',
  },
  loanPurposeEverydayExpenses: {
    id: 'rahoitu.fi.loanPurposeEverydayExpenses',
    defaultMessage: 'Consumption',
    description: 'Purpose of the loan - Shopping/consumption',
  },
  loanPurposeRenovation: {
    id: 'rahoitu.fi.loanPurposeRenovation',
    defaultMessage: 'Renovation',
    description: 'Purpose of the loan - Renovation',
  },
  loanPurposeTravel: {
    id: 'rahoitu.fi.loanPurposeTravel',
    defaultMessage: 'Travel',
    description: 'Purpose of the loan - travel',
  },
  loanPurposeBusiness: {
    id: 'rahoitu.fi.loanPurposeBusiness',
    defaultMessage: 'Business',
    description: 'Purpose of the loan - Business',
  },
  loanPurposeHealth: {
    id: 'rahoitu.fi.loanPurposeHealth',
    defaultMessage: 'Health',
    description: 'Purpose of the loan - Health',
  },
  loanPurposeServices: {
    id: 'rahoitu.fi.loanPurposeServices',
    defaultMessage: 'Services',
    description: 'Purpose of the loan - Services',
  },
  loanPurposeInvestment: {
    id: 'rahoitu.fi.loanPurposeInvestment',
    defaultMessage: 'Investment',
    description: 'Purpose of the loan - Investment',
  },
  loanPurposeStudies: {
    id: 'rahoitu.fi.loanPurposeStudies',
    defaultMessage: 'Studies',
    description: 'Purpose of the loan - Studies',
  },
  loanPurposeOther: {
    id: 'rahoitu.fi.loanPurposeOther',
    defaultMessage: 'Other',
    description: 'Purpose of the loan - other',
  },
  mergeLoanYes: {
    id: 'rahoitu.fi.mergeLoanYes',
    defaultMessage: 'Yes',
    description: '',
  },
  mergeLoanNo: {
    id: 'rahoitu.fi.mergeLoanNo',
    defaultMessage: 'No',
    description: '',
  },
  monthlySalaryPlaceholder: {
    id: 'rahoitu.fi.monthlySalaryPlaceholder',
    defaultMessage: 'E.g. € 2 500',
    description: 'Default-text för inkomst i formulär.',
  },
  monthlySalaryLabel: {
    id: 'rahoitu.fi.monthlySalaryLabel',
    defaultMessage: 'Monthly income before taxes (€/mth)',
    description: 'Label-text för inkomst i formulär.',
  },
  coApplicantMonthlySalaryPlaceholder: {
    id: 'rahoitu.fi.coApplicantMonthlySalaryPlaceholder',
    defaultMessage: 'E.g. 2 500',
    description: 'Default-text för inkomst i formulär.',
  },
  coApplicantMonthlySalaryLabel: {
    id: 'rahoitu.fi.coApplicantMonthlySalaryLabel',
    defaultMessage: 'Co-applicant’s monthly income before taxes (€/mth)',
    description: 'Label-text för inkomst i formulär.',
  },
  monthlySalaryBeforeTaxRequired: {
    id: 'rahoitu.fi.monthlySalaryBeforeTaxRequired',
    defaultMessage: 'Please provide your monthly income before taxes (€/mth)',
    description: 'Error message informing the applicant that a monthly salary before taxes must be provided',
  },
  monthlySalaryAfterTaxRequired: {
    id: 'rahoitu.fi.monthlySalaryAfterTaxRequired',
    defaultMessage: 'Please provide your monthly income after taxes (€/mth)',
    description: 'Error message informing the applicant that a monthly salary after taxes must be provided',
  },
  monthlySalaryOnlynumbers: {
    id: 'rahoitu.fi.monthlySalaryOnlynumbers',
    defaultMessage: 'Please write your income in numbers only',
    description: 'Error message informing the customer that only numbers are allowed to be passed in',
  },
  monthlySalaryTooLow: {
    id: 'rahoitu.fi.monthlySalaryTooLow',
    defaultMessage: 'Your income must be at least € {minSalary, number} /mth in order to apply',
    description: 'Error message informing the customer that the monthly salary provided is to low and information that you need to have a monthly salary of at least x in order to compare loans',
  },
  monthlySalaryTooHigh: {
    id: 'rahoitu.fi.monthlySalaryTooHigh',
    defaultMessage: 'Your income must not be more than € {maxSalary, number} /mth for you to apply',
    description: 'Error message informing the customer that the monthly salary provided is to high',
  },
  monthlySalaryAfterTaxInvalid: {
    id: 'rahoitu.fi.monthlySalaryAfterTaxInvalid',
    defaultMessage: 'Income after tax can not be higher than income before tax',
    description: 'Error message informing the customer that the monthly salary before tax is to high',
  },
  coApplicantMonthlySalaryRequired: {
    id: 'rahoitu.fi.coApplicantMonthlySalaryRequired',
    defaultMessage: "Please enter your co-applicant's monthly income before tax (€/mth)",
    description: 'Error message informing the applicant that a monthly salary must be provided',
  },
  coApplicantMonthlySalaryOnlynumbers: {
    id: 'rahoitu.fi.coApplicantMonthlySalaryOnlynumbers',
    defaultMessage: "Please enter your co-applicant's income in numbers only",
    description: 'Error message informing the customer that only numbers are allowed to be passed in',
  },
  coApplicantMonthlySalaryTooLow: {
    id: 'rahoitu.fi.coApplicantMonthlySalaryTooLow',
    defaultMessage: 'Their income must be at least € {minSalary, number} /mth in order to apply',
    description: 'Error message informing the customer that the monthly salary provided is to low and information that you need to have a monthly salary of at least x in order to compare loans',
  },
  coApplicantMonthlySalaryTooHigh: {
    id: 'rahoitu.fi.coApplicantMonthlySalaryTooHigh',
    defaultMessage: 'Their income must not be more than € {maxSalary, number} /mth for you to apply',
    description: 'Error message informing the customer that the monthly salary provided is to high',
  },
  coApplicantSpouseMonthlyIncomeLabel: {
    id: 'rahoitu.fi.coApplicantSpouseMonthlyIncomeLabel',
    defaultMessage: "Co-applicant's spouse monthly income before taxes (€/mth)",
    description: 'Label for spouse monthly income field',
  },
  coApplicantSpouseMonthlyIncomePlaceholder: {
    id: 'rahoitu.fi.coApplicantSpouseMonthlyIncomePlaceholder',
    defaultMessage: "Co-applicant's spouse monthly income before taxes (€/mth)",
    description: 'Placeholder for spouse monthly income field',
  },
  coApplicantSpouseMonthlySalaryBeforeTaxRequired: {
    id: 'rahoitu.fi.coApplicantSpouseMonthlySalaryBeforeTaxRequired',
    defaultMessage: "Co-applicant's spouse monthly income before tax is required",
    description: 'Error message for not filling in spouse monthly income',
  },
  coApplicantSpouseMonthlySalaryOnlynumbers: {
    id: 'rahoitu.fi.coApplicantSpouseMonthlySalaryOnlynumbers',
    defaultMessage: "Please write your co-applicant's spouse income in numbers only",
    description: 'Error message for only allowed to use numbers',
  },
  coApplicantSpouseMonthlySalaryMustBePositive: {
    id: 'rahoitu.fi.coApplicantSpouseMonthlySalaryMustBePositive',
    defaultMessage: "Co-applicant's spouse monthly income can not be negative",
    description: 'Error message for only allowed to use numbers',
  },
  otherIncomeBeforeTaxRequired: {
    id: 'rahoitu.fi.otherMonthlyIncomeBeforeTaxRequired',
    defaultMessage: 'Please provide your other monthly income before taxes (€/mth)',
    description: 'Error message informing the applicant that additional monthly income before taxes must be provided',
  },
  otherIncomeAfterTaxRequired: {
    id: 'rahoitu.fi.otherMonthlyIncomeAfterTaxRequired',
    defaultMessage: 'Please provide your other monthly income after taxes (€/mth)',
    description: 'Error message informing the applicant that additional monthly income after taxes must be provided',
  },
  otherIncomeOnlyNumbers: {
    id: 'rahoitu.fi.otherMonthlySalaryOnlynumbers',
    defaultMessage: 'Please write your other monthly income in numbers only',
    description: 'Error message informing the customer that only numbers are allowed to be passed in',
  },
  coApplicantOtherIncomeBeforeTaxRequired: {
    id: 'rahoitu.fi.coApplicantOtherMonthlyIncomeBeforeTaxRequired',
    defaultMessage: "Please provide your co-applicant's other monthly income before taxes (€/mth)",
    description: "Error message informing the co-applicant's additional monthly income before taxes must be provided",
  },
  coApplicantOtherIncomeAfterTaxRequired: {
    id: 'rahoitu.fi.coApplicantOtherMonthlyIncomeAfterTaxRequired',
    defaultMessage: "Please provide your co-applicant's other monthly income after taxes (€/mth)",
    description: "Error message informing the co-applicant's additional monthly income after taxes must be provided",
  },
  coApplicantOtherIncomeOnlyNumbers: {
    id: 'rahoitu.fi.coApplicantOtherMonthlySalaryOnlynumbers',
    defaultMessage: "Please write your co-applicant's other monthly income in numbers only",
    description: 'Error message informing the customer that only numbers are allowed to be passed in for their co-applicant',
  },
  nationalIdPlaceholder: {
    id: 'rahoitu.fi.nationalIdPlaceholder',
    defaultMessage: 'DDMMYY-XXXX',
    description: 'Default-text för personnummer i formulär.',
  },
  nationalIdLabel: {
    id: 'rahoitu.fi.nationalIdLabel',
    defaultMessage: 'National ID',
    description: 'Label-text för personnummer i formulär.',
  },
  nationalIdMustBeUnique: {
    id: 'rahoitu.fi.nationalIdMustBeUnique',
    defaultMessage: 'The main applicant and the co-applicant cannot be the same person.',
    description: 'Informing the customer that the applicant and co-applicant can not be the same person/use the same social security number',
  },
  nationalIdRequired: {
    id: 'rahoitu.fi.nationalIdRequired',
    defaultMessage: 'Please enter your Finnish ID number',
    description: 'Error message for when a social security number must be provided',
  },
  nationalIdWrongFormat: {
    id: 'rahoitu.fi.nationalIdWrongFormat',
    defaultMessage: 'Please write in the correct format, 10 or 12 digits, e.g. 221180-012A',
    description: 'Error message when the social security number is in the wrong format. Provides an example of a correct format social security number',
  },
  nationalIdWrongDate: {
    id: 'rahoitu.fi.nationalIdWrongDate',
    defaultMessage: 'Your birth date {dateOfBirthString} is not valid. Please make sure there are no mistakes',
    description: 'Error message when providing birth date incorrectly',
  },
  nationalIdDateInTheFuture: {
    id: 'rahoitu.fi.nationalIdDateInTheFuture',
    defaultMessage: '{dateOfBirthString} is a future date, and cannot be used',
    description: 'Error message when choosing date of birth as a date in the future',
  },
  nationalIdTooYoung: {
    id: 'rahoitu.fi.nationalIdTooYoung',
    defaultMessage: 'You have to be at least {minAge} years of age to submit an application.',
    description: 'Error message when providing a date of birth making you younger than the minimum age allowed',
  },
  nationalIdTooOld: {
    id: 'rahoitu.fi.nationalIdTooOld',
    defaultMessage: 'Unfortunately, we can only help people up to {maxAge} years of age.',
    description: 'Error message when providing a date of birth making you older than the maximum age allowed',
  },
  nationalIdInvalid: {
    id: 'rahoitu.fi.nationalIdInvalid',
    defaultMessage: 'Your 4 last digits do not seem to match your birth date. Please verify them',
    description: 'Error message when providing a social security number wrongly formatted. Asks the customer to review their last four digits in their social security number',
  },
  coApplicantNationalIdPlaceholder: {
    id: 'rahoitu.fi.coApplicantNationalIdPlaceholder',
    defaultMessage: 'DDMMYY-XXXX',
    description: 'Default-text för personnummer i formulär.',
  },
  coApplicantNationalIdLabel: {
    id: 'rahoitu.fi.coApplicantNationalIdLabel',
    defaultMessage: 'National ID of co-applicant',
    description: 'Label-text för personnummer i formulär.',
  },
  coApplicantNationalIdrequired: {
    id: 'rahoitu.fi.coApplicantNationalIdrequired',
    defaultMessage: "Please enter your co-applicant's Finnish ID number",
    description: 'Error message for when a social security number must be provided',
  },
  coApplicantNationalIdWrongFormat: {
    id: 'rahoitu.fi.coApplicantNationalIdWrongFormat',
    defaultMessage: 'Please write in the correct format, 10 or 12 digits, e.g. 221180-012A',
    description: 'Error message when the social security number is in the wrong format. Provides an example of a correctly formatted social security number',
  },
  coApplicantNationalIdWrongDate: {
    id: 'rahoitu.fi.coApplicantNationalIdWrongDate',
    defaultMessage: 'Their birth date {dateOfBirthString} is not valid, please make sure your input is correct',
    description: 'Error message when providing birth date incorrectly',
  },
  coApplicantNationalIdDateInTheFuture: {
    id: 'rahoitu.fi.coApplicantNationalIdDateInTheFuture',
    defaultMessage: '{dateOfBirthString} is a future date, and cannot be used',
    description: 'Error message when choosing date of birth as a date in the future',
  },
  coApplicantNationalIdInvalid: {
    id: 'rahoitu.fi.coApplicantNationalIdInvalid',
    defaultMessage: 'Their 4 last digits do not seem to match the birth date. Please verify them',
    description: 'Error message when providing a social security number wrongly formatted. Asks the customer to review their last four digits in their social security number',
  },
  phoneNumberPlaceholder: {
    id: 'rahoitu.fi.phoneNumberPlaceholder',
    defaultMessage: '04__ __ __ __',
    description: 'Default-text för mobilnummer i formulär.',
  },
  phoneNumberLabel: {
    id: 'rahoitu.fi.phoneNumberLabel',
    defaultMessage: 'Phone number',
    description: '',
  },
  phoneNumberMustBeUnique: {
    id: 'rahoitu.fi.phoneNumberMustBeUnique',
    defaultMessage: 'The main applicant and the co-applicant cannot have the same phone number.',
    description: 'Informing the customer/applicant that their co-applicant may not have an identical phone number as theirs',
  },
  phoneNumberRequired: {
    id: 'rahoitu.fi.phoneNumberRequired',
    defaultMessage: 'Please enter your phone number',
    description: '',
  },
  phoneNumberWrongBeginning: {
    id: 'rahoitu.fi.phoneNumberWrongBeginning',
    defaultMessage: 'We only accept cell phone numbers. The number needs to start with 0, +358 or 00358',
  },
  phoneNumberFormatError: {
    id: 'rahoitu.fi.phoneNumberFormatError',
    defaultMessage: 'Must begin with 0 or Finnish country code (+358).',
    description: '',
  },
  phoneNumberPrefixError: {
    id: 'rahoitu.fi.phoneNumberPrefixError',
    defaultMessage: 'Your phone number must begin with 0, +358 or 00358',
    description: '',
  },
  phoneNumberWrongNumber: {
    id: 'rahoitu.fi.phoneNumberWrongNumber',
    defaultMessage: 'The phone number appears to be incorrect. Please enter a valid Finnish mobile phone number.',
  },
  phoneNumberGeneralError: {
    id: 'rahoitu.fi.phoneNumberGeneralError',
    defaultMessage: 'The phone number appears to be incorrect. Please enter a valid Finnish mobile phone number.',
  },
  phoneNumberTooShort: {
    id: 'rahoitu.fi.phoneNumberTooShort',
    defaultMessage: "The phone number is too short. Verify that you haven't forgotten any digits",
    description: 'Error message for when the customer fills in a too short phone number.',
  },
  phoneNumberTooLong: {
    id: 'rahoitu.fi.phoneNumberTooLong',
    defaultMessage: "The phone number is too long. Make sure you haven't added too many digits",
    description: 'Error message for when the customer fills in a too long phone number.',
  },
  coApplicantPhoneNumberPlaceholder: {
    id: 'rahoitu.fi.coApplicantPhoneNumberPlaceholder',
    defaultMessage: '04__ __ __ __',
    description: 'Default-text för coApplicant mobilnummer i formulär.',
  },
  coApplicantPhoneNumberLabel: {
    id: 'rahoitu.fi.coApplicantPhoneNumberLabel',
    defaultMessage: 'Phone number of co-applicant',
    description: 'label for coApplicant mobile number in form.',
  },
  coApplicantPhoneNumberRequired: {
    id: 'rahoitu.fi.coApplicantPhoneNumberRequired',
    defaultMessage: "Please enter your co-applicant's phone number",
  },
  coApplicantEmploymentSinceYearPlaceholder: {
    id: 'rahoitu.fi.coApplicantEmploymentSinceYearPlaceholder',
    defaultMessage: 'e.g 2002',
    description: 'Default text for "employment since"-input in years, placed inside form',
  },
  coApplicantEmploymentSinceYearLabel: {
    id: 'rahoitu.fi.coApplicantEmploymentSinceYearLabel',
    defaultMessage: 'Co-applicant employed since (year)',
    description: 'Label for "employment since"-input in years, placed inside form',
  },
  coApplicantEmploymentSinceMonthLabel: {
    id: 'rahoitu.fi.coApplicantEmploymentSinceMonthLabel',
    defaultMessage: 'Co-applicant employed since (month)',
    description: 'Label for "employment since"-input in months, placed inside form',
  },
  coApplicantEmploymentSinceMonthPlaceholder: {
    id: 'rahoitu.fi.coApplicantEmploymentSinceMonthPlaceholder',
    defaultMessage: 'Month',
    description: 'Default text for "employment since"-input in months, placed inside form',
  },
  betterTermsText: {
    id: 'rahoitu.fi.betterTermsText',
    defaultMessage: 'Having a co-applicant often results in better interest rates.',
    description: '',
  },
  submitErrorGeneral: {
    id: 'rahoitu.fi.submitErrorGeneral',
    defaultMessage: 'Your application could not be submitted. Customer service is more than happy to help you through the process. Call: 0942456000 or click the button below and we will call you as soon as we can on <i>{customerPhoneNumber}</i>',
    description: 'Default-text för back-end fel i formulär.',
  },
  submitErrorUncaught: {
    id: 'rahoitu.fi.submitErrorUncaught',
    defaultMessage: 'We have technical issues at the moment and your application could not be submitted. Please contact customer service for more help 0942456000',
    description: 'Default-text for when a customer tries to submit their application but there was an error on the client side.',
  },
  validationErrorGeneral: {
    id: 'rahoitu.fi.validationErrorGeneral',
    defaultMessage: 'Some of the fields are not filled in correctly. Please correct these and try to submit again.',
    description: 'Default-text för fel i fält.',
  },
  validationErrorField: {
    id: 'rahoitu.fi.validationErrorField',
    defaultMessage: 'This field is not filled in correctly. Please correct it and try submitting again.',
    description: 'Default-text för fel i fält.',
  },
  fieldAlreadyInUseEmail: {
    id: 'rahoitu.fi.fieldAlreadyInUseEmail',
    defaultMessage: 'This email address already belongs to an application.',
    description: 'Field error message informing the customer that the given email is already in use in another application',
  },
  fieldAlreadyInUseNationalId: {
    id: 'rahoitu.fi.fieldAlreadyInUseNationalId',
    defaultMessage: 'You have an ongoing application, to access it — click "Log in" in the menu. To make changes to your application, please call customer service on: <span>{phoneNumber}</span>',
    description: 'Default-text för back-end fel i formulär.',
  },
  formSubmit: {
    id: 'rahoitu.fi.formSubmit',
    defaultMessage: 'Apply now - for free',
    description: 'Label for submit button',
  },
  monthlyCostApproximateMonthlyCostLabel: {
    id: 'rahoitu.fi.monthlyCostApproximateMonthlyCostLabel',
    defaultMessage: 'Monthly cost approx. *',
    description: 'The label for the apprax. monthly cost',
  },
  monthlyCostApproximateCostLabel: {
    id: 'rahoitu.fi.monthlyCostApproximateCostLabel',
    defaultMessage: 'Approximate cost',
    description: 'approx cost',
  },
  monthlyCostApproximateCostPerMonthDesktop: {
    id: 'rahoitu.fi.monthlyCostApproximateCostPerMonthDesktop',
    defaultMessage: '{monthlyCost} / month *',
    description: 'Shows the apprx. monthly cost on desktop',
  },
  monthlyCostApproximateCostPerMonthMobile: {
    id: 'rahoitu.fi.monthlyCostApproximateCostPerMonthMobile',
    defaultMessage: '<strong>{monthlyCost}</strong>/mth*',
    description: 'Shows the apprx. monthly cost on mobile',
  },
  monthlyCostApproximateCostLabelYears: {
    id: 'rahoitu.fi.monthlyCostApproximateCostLabelYears',
    defaultMessage: 'in <strong> {repaymentTime} years </strong>',
    description: 'Repayment time in years',
  },
  combinedLoanConsolidationAmount: {
    id: 'rahoitu.fi.combinedLoanConsolidationAmount',
    defaultMessage: 'Loans to consolidate:  {consolidationAmount}',
    description: 'Part of the loan that is consolidation amount',
  },
  samblaPlusText: {
    id: 'rahoitu.fi.samblaPlusText',
    defaultMessage: 'I want to become a member of Sambla Plus to receive newsletters and offers via SMS and email. ',
    description: 'Text besides sambla plus checkbox in form',
  },
  samblaPlusReadMore: {
    id: 'rahoitu.fi.samblaPlusReadMore',
    defaultMessage: 'Read more',
    description: 'Text for link to read about Sambla Plus',
  },
  repaymentTimeWarning: {
    id: 'rahoitu.fi.repaymentTimeWarning',
    defaultMessage: 'Most lenders offer a maximum repayment time of 15 years. These banks offer a maximum of 15 years, even if you apply for longer than that.',
    description: 'warning when entering over 15 years repayment time',
  },
  loanCalculatorTotalLoanAmountLabel: {
    id: 'rahoitu.fi.loanCalculator.totalLoanAmountLabel',
    defaultMessage: 'Loan amount',
    description: 'Label-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  loanCalculatorTotalLoanAmountPlaceholder: {
    id: 'rahoitu.fi.loanCalculator.totalLoanAmountPlaceholder',
    defaultMessage: 'X XXX',
    description: 'Default-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  loanCalculatorTotalLoanAmountSuffix: {
    id: 'rahoitu.fi.loanCalculator.totalLoanAmountSuffix',
    defaultMessage: '€',
    description: 'Suffix-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  loanCalculatorTotalLoanAmountTooltip: {
    id: 'rahoitu.fi.loanCalculator.totalLoanAmountTooltip',
    defaultMessage: 'Please enter the total loan amount for the loan you want to use for calculation',
    description: 'Tooltip-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  loanCalculatorInterestRateLabel: {
    id: 'rahoitu.fi.loanCalculator.interestRateLabel',
    defaultMessage: 'Interest rate',
    description: 'Label for interest rate.',
  },
  loanCalculatorInterestRatePlaceholder: {
    id: 'rahoitu.fi.loanCalculator.interestRatePlaceholder',
    defaultMessage: 'X.XX',
    description: 'Default text for interest rate.',
  },
  loanCalculatorInterestRateSuffix: {
    id: 'rahoitu.fi.loanCalculator.interestRateSuffix',
    defaultMessage: '%',
    description: 'Suffix for interest rate.',
  },
  loanCalculatorRepaymentTimeLabel: {
    id: 'rahoitu.fi.loanCalculator.repaymentTimeLabel',
    defaultMessage: 'Repayment time',
    description: 'Label for repayment time inside form',
  },
  loanCalculatorRepaymentTimePlaceholder: {
    id: 'rahoitu.fi.loanCalculator.repaymentTimePlaceholder',
    defaultMessage: '0',
    description: 'Default text for repayment time inside form',
  },
  loanCalculatorRepaymentTimeSuffix: {
    id: 'rahoitu.fi.loanCalculator.repaymentTimeSuffix',
    defaultMessage: 'years',
    description: 'Suffix for repayment time inside form',
  },
  loanCalculatorSetUpFeeLabel: {
    id: 'rahoitu.fi.loanCalculator.setUpFeeLabel',
    defaultMessage: 'Set-up Fee',
    description: 'Label text for start up fee.',
  },
  loanCalculatorSetUpFeePlaceholder: {
    id: 'rahoitu.fi.loanCalculator.setUpFeePlaceholder',
    defaultMessage: '€ 30',
    description: 'Placeholder text for start up fee.',
  },
  loanCalculatorAviaFeeLabel: {
    id: 'rahoitu.fi.loanCalculator.aviaFeeLabel',
    defaultMessage: 'Avia Fee',
    description: 'Label text for fee.',
  },
  loanCalculatorAviaFeePlaceholder: {
    id: 'rahoitu.fi.loanCalculator.aviaFeePlaceholder',
    defaultMessage: '€ 3',
    description: 'Label text for avia fee.',
  },
  loanCalculatorNextSlideButtonHeading: {
    id: 'rahoitu.fi.loanCalculator.nextSlideButtonHeading',
    defaultMessage: 'Do you want to apply for a loan based on the calculation above?',
    description: 'Title above the teleporter',
  },
  loanCalculatorNextSlideButtonSubHeading: {
    id: 'rahoitu.fi.loanCalculator.nextSlideButtonSubHeading',
    defaultMessage: 'Fill out the information on the next page',
    description: 'Subtitle above the teleporter',
  },
  loanCalculatorNextSlideButton: {
    id: 'rahoitu.fi.loanCalculator.nextSlideButton',
    defaultMessage: 'Next',
    description: 'general progress button label',
  },
  loanCalculatorAmortizationTypeLabel: {
    id: 'rahoitu.fi.loanCalculator.summaryPage.amortizationTypeLabel',
    defaultMessage: 'Type of amortization',
    description: 'Label for which type of amortization the user wants to calculate with',
  },
  loanCalculatorAmortizationTypeAnnuity: {
    id: 'rahoitu.fi.loanCalculator.summaryPage.amortizationTypeAnnuity',
    defaultMessage: 'Annuity',
    description: 'Text for amortization type annuity',
  },
  loanCalculatorAmortizationTypeStraight: {
    id: 'rahoitu.fi.loanCalculator.summaryPage.amortizationTypeStraight',
    defaultMessage: 'Straight',
    description: 'Text for amortization type straight',
  },
  loanCalculatorTotalCostsForLoan: {
    id: 'rahoitu.fi.loanCalculator.summaryPage.totalCostsForLoan',
    defaultMessage: 'Total cost',
    description: 'Text for total costs',
  },
  loanCalculatorTotalCostsAndInterestAndFeesForLoan: {
    id: 'rahoitu.fi.loanCalculator.summaryPage.totalCostsAndInterestAndFeesForLoan',
    defaultMessage: 'Interest rate and fees',
    description: 'Text for total costs and interest and fees for loan',
  },
  loanCalculatorMonthlyCost: {
    id: 'rahoitu.fi.loanCalculator.summaryPage.monthlyCost',
    defaultMessage: 'Monthly cost:',
    description: 'Text monthly cost',
  },
  loanCalculatorMonthlyCostMonthOne: {
    id: 'rahoitu.fi.loanCalculator.summaryPage.monthlyCostMonthOne',
    defaultMessage: 'Monthly cost, month 1:',
    description: 'Text monthly cost',
  },
  loanCalculatorMonthlyCostOverviewButtonText: {
    id: 'rahoitu.fi.loanCalculator.summaryPage.monthlyCostOverviewButtonText',
    defaultMessage: 'See overview of monthly cost',
    description: 'Text for button',
  },
  monthlySalaryTooltip: {
    id: 'rahoitu.fi.monthlySalaryTooltip',
    defaultMessage: 'Please enter your total monthly income from all sources (€ before taxes). This may include salary, commission, and pension. Note that you might need to prove your stated income through payment slips and alike.',
    description: 'Tooltip-text för inkomst i formulär.',
  },
  coApplicantMonthlySalaryTooltip: {
    id: 'rahoitu.fi.coApplicantMonthlySalaryTooltip',
    defaultMessage: "Enter your co-applicant's approximate total monthly income from all sources (€ before taxes). This may include salary, commission and pension. Note that your co-applicant might need to prove their stated income through payslips and alike.",
    description: 'Tooltip-text for coApplicant income in form.',
  },
  coApplicantNationalIdTooltip: {
    id: 'rahoitu.fi.coApplicantNationalIdTooltip',
    defaultMessage: 'Since the application is personal, we need to verify your co-applicant’s national ID (don’t write your own). This needs to be a Finnish social security number',
    description: 'Tooltip-text for coApplicant in form.',
  },
  coApplicantEmploymentSinceYearTooltip: {
    id: 'rahoitu.fi.coApplicantEmploymentSinceYearTooltip',
    defaultMessage: 'Please enter approximately the year when your co-applicant started at their current job. Use 4 numbers, e.g "2002".',
    description: 'Tooltip for "employment since"-input',
  },
  coApplicantEmploymentSinceMonthTooltip: {
    id: 'rahoitu.fi.coApplicantEmploymentSinceMonthTooltip',
    defaultMessage: "Please select approximately the month that your co-applicant started at their current job. If you don't know exactly, pick what you think it is.",
    description: 'Tooltip for "employment since"-input',
  },
  loanCalculatorInterestRateTooltip: {
    id: 'rahoitu.fi.loanCalculator.interestRateTooltip',
    defaultMessage: 'Effective interest rate indicates the total cost of the loan and it includes all costs in addition to the interest such as the opening fee and monthly account management fee.',
    description: 'Tooltip for interest rate for the loan in this form.',
  },
  loanCalculatorRepaymentTimeTooltip: {
    id: 'rahoitu.fi.loanCalculator.repaymentTimeTooltip',
    defaultMessage: 'The repayment time refers to the amount of time you plan to pay back your loan. Depending on the time, the monthly cost of your loan might change, where a longer repayment time often means a lower monthly cost.',
    description: 'Tooltip for repayment time inside form, asking the applicant to fill in how long they wish to take to repay their loan. Also informing them that the longer time of repayment, the lower the monthly cost',
  },
  loanCalculatorSetUpFeeTooltip: {
    id: 'rahoitu.fi.loanCalculator.setUpFeeTooltip',
    defaultMessage: 'Some banks have one single starting fee and/or recurring admin fees connected to your loan. You can find this info in the terms documentation of the loan or on the invoice.',
    description: 'Tooltip text for start up fee.',
  },
  loanCalculatorAviaFeeTooltip: {
    id: 'rahoitu.fi.loanCalculator.aviaFeeTooltip',
    defaultMessage: 'Some banks have one single starting fee and/or recurring admin fees connected to your loan. You can find this info in the terms documentation of the loan or on the invoice.',
    description: 'Tooltip text for avia fee.',
  },
  loanCalculatorAmortizationTypeTooltip: {
    id: 'rahoitu.fi.loanCalculator.summaryPage.amortizationTypeTooltip',
    defaultMessage: 'Amortization essentially means that you pay back a small portion of the loan every month. Sambla’s partners can provide either Annuity loans or Straight loans. Annuity loans are the most common and means you pay the same monthly cost every month. You can read more about amortization <a>here.</a>',
    description: 'Text for tooltip',
  },
  loanCalculatorTotalCostsForLoanTooltip: {
    id: 'rahoitu.fi.loanCalculator.summaryPage.totalCostsForLoanTooltip',
    defaultMessage: 'You need to pay back the whole sum of the loan through the amortization plan, but there are also fees and interest costs connected to the loan that are added and needs to be paid.',
    description: 'Text for tooltip',
  },
  loanCalculatorTotalCostsAndInterestAndFeesForLoanTooltip: {
    id: 'rahoitu.fi.loanCalculator.summaryPage.totalCostsAndInterestAndFeesForLoanTooltip',
    defaultMessage: 'These are the total fees, interest rate and costs connected to the loan. This excludes the loan amount, and with it, the amortization.',
    description: 'Text for tooltip',
  },
  loanCalculatorMonthlyCostTooltip: {
    id: 'rahoitu.fi.loanCalculator.summaryPage.monthlyCostTooltip',
    defaultMessage: 'This is the recurring monthly cost for you until you have repaid your loan. Depending on your amortization type, these costs might change with time.',
    description: 'Text for tooltip',
  },
  bankAccountTooltip: {
    id: 'rahoitu.fi.bankAccountTooltip',
    defaultMessage: 'Please enter your 16 digit IBAN number starting with FI. You can find this in your online bank under account details or by contacting your bank directly',
    description: 'Tooltip for bank account field',
  },
  coApplicantPrivateLoansTooltip: {
    id: 'rahoitu.fi.coApplicantPrivateLoansTooltip',
    defaultMessage: 'Please enter the current debt of the co-applicant’s consumer/private loan/loans. Consumer loans are loans without security that can be used for anything - usually for consumption, renovation or paying other expensive debts. The banks and lenders need this information to get an overview of their financial situation and to be able to give out loan offers. Do not specify other debts in this field. ',
    description: 'private loans tooltip',
  },
  coApplicantCarLoansTooltip: {
    id: 'rahoitu.fi.coApplicantCarLoansTooltip',
    defaultMessage: 'Please enter the current debt of the co-applicant’s car loan/loans. The banks and lenders need this information to get an overview of their financial situation and to be able to give out loan offers. Do not specify other debts in this field. Car loans are used to finance a car or a vehicle. It is a type of loan with security through the vehicle itself. These loans are often tied to the vehicle. ',
    description: 'car loans tooltip',
  },
  coApplicantHouseLoansTooltip: {
    id: 'rahoitu.fi.coApplicantHouseLoansTooltip',
    defaultMessage: 'Please enter the current debt of the co-applicant’s mortgage. House loans/mortgages are loans used for financing the purchase of a property. These are loans with security through the property itself.The banks and lenders need this information to get an overview of their financial situation and to be able to give out loan offers. Do not specify other debts in this field.',
    description: 'House loans tooltip',
  },
  coApplicantStudentLoansTooltip: {
    id: 'rahoitu.fi.coApplicantStudentLoansTooltip',
    defaultMessage: 'Please enter the current debt of the co-applicant’s student loan. The banks and lenders need this information to get an overview of their financial situation and to be able to give out loan offers. Do not specify other debts in this field. ',
    description: 'student loans tooltip',
  },
  coApplicantCreditCardLoansTooltip: {
    id: 'rahoitu.fi.coApplicantCreditCardLoansTooltip',
    defaultMessage: 'Please enter the total debt of the co-applicant’s credit card/cards. The banks and lenders need this information to get an overview of their financial situation and to be able to give out loan offers. A credit card loan is money you borrow against your credit card limit. You can repay the entire debt per month or over a longer period of time. Do not specify other debts in this field.',
    description: 'Credit card loans tooltip',
  },
  isCoApplicantPepTooltip: {
    id: 'rahoitu.fi.isCoApplicantPepTooltip',
    defaultMessage: 'Please enter yes if your co-applicant or anyone close to them is a person in a Politically Exposed Position. PEP is a person who has/had important public functions in a state/international organization. E.g. Head of state, head of government, ministers, deputy or assistant minister or members of parliaments.',
    description: 'Tooltip for if the coApplicant is a politically exposed person',
  },
  coApplicantCreditCardTooltip: {
    id: 'rahoitu.fi.coApplicantCreditCardTooltip',
    defaultMessage: 'Does your co-applicant have any active credit cards?',
    description: 'Tooltip for if the coApplicant have credit cards',
  },
  coApplicantOtherIncomeTooltip: {
    id: 'rahoitu.fi.coApplicantOtherIncomeTooltip',
    defaultMessage: 'Does the co-applicant have additional sources of income that are not stated already? This may include for example income from letting an apartment, income from a hobby, or state allowance. They need to prove their additional income through tax or payment slips',
    description: 'Tooltip for coApplicant has other income',
  },
  loanCalculatorSummaryTablePageDescription: {
    id: 'rahoitu.fi.loanCalculator.summaryPage.summaryTablePageDescription',
    defaultMessage: 'More info',
    description: 'Text for page description',
  },
  loanCalculatorEffectiveInterest: {
    id: 'rahoitu.fi.loanCalculator.summaryPage.effectiveInterest',
    defaultMessage: 'Effective interest rate:',
    description: 'Text for summary table effective interest',
  },
  loanCalculatorSummaryTableColumnMonth: {
    id: 'rahoitu.fi.loanCalculator.summaryPage.summaryTableColumnMonth',
    defaultMessage: 'Month',
    description: 'Text for summary table month column',
  },
  loanCalculatorSummaryTableColumnDebtBeforeAmortization: {
    id: 'rahoitu.fi.loanCalculator.summaryPage.summaryTableColumnDebtBeforeAmortization',
    defaultMessage: 'Debt before amortization',
    description: 'Text for summary table debt before amortization column',
  },
  loanCalculatorSummaryTableColumnAmortization: {
    id: 'rahoitu.fi.loanCalculator.summaryPage.summaryTableColumnAmortization',
    defaultMessage: 'Amortization',
    description: 'Text for summary table amortization column',
  },
  loanCalculatorSummaryTableColumnInterestCost: {
    id: 'rahoitu.fi.loanCalculator.summaryPage.summaryTableColumnInterestCost',
    defaultMessage: 'Interest rate',
    description: 'Text for summary table interest cost column',
  },
  loanCalculatorSummaryTableColumnFeeCost: {
    id: 'rahoitu.fi.loanCalculator.summaryPage.summaryTableColumnFeeCost',
    defaultMessage: 'Fees',
    description: 'Text for summary table fee cost column',
  },
  loanCalculatorSummaryTableColumnToPay: {
    id: 'rahoitu.fi.loanCalculator.summaryPage.summaryTableColumnToPay',
    defaultMessage: 'To pay',
    description: 'Text for summary table to pay column',
  },
  loanCalculatorCurrencySuffix: {
    id: 'rahoitu.fi.loanCalculator.summaryPage.currencySuffix',
    defaultMessage: '€',
    description: 'Suffix text for amounts in the loan calculator etc. kr or €',
  },
  feesCostRequired: {
    id: 'rahoitu.fi.loanCalculator.feesCostRequired',
    defaultMessage: 'Please enter the fee',
    description: 'Fee description',
  },
  feesCostOnlyDigits: {
    id: 'rahoitu.fi.loanCalculator.feesCostOnlyDigits',
    defaultMessage: 'Please write your fees cost in numbers only',
    description: 'Inform the customer that they may only pass in digits in the input for the fees cost.',
  },
  feesCostTooHigh: {
    id: 'rahoitu.fi.loanCalculator.feesCostTooHigh',
    defaultMessage: 'Your fees cost cannot be more than € {maxCost, number} /mth.',
    description: 'Inform the customer that we may unfortunately only accept a fees cost of "x" and that their cost is to high',
  },
  feesCostTooLow: {
    id: 'rahoitu.fi.loanCalculator.feesCostTooLow',
    defaultMessage: 'Please enter the fees connected to the loan',
    description: 'Inform the customer that their passed in fees cost is set to low, for example "0"',
  },
  feesCostStartWithZero: {
    id: 'rahoitu.fi.loanCalculator.feesCostStartWithZero',
    defaultMessage: "Please don't start your input with a 0",
    description: 'Inform the customer that their passed in fees cost is set to low, for example "0"',
  },
  citizenshipLabel: {
    id: 'rahoitu.fi.citizenshipLabel',
    defaultMessage: 'Citizenship',
    description: 'Label for citizen section',
  },
  coApplicantCitizenshipLabel: {
    id: 'rahoitu.fi.coApplicantCitizenshipLabel',
    defaultMessage: "Co-applicant's citizenship",
    description: 'Label for coapplicant citizen section',
  },
  citizenshipFIN: {
    id: 'rahoitu.fi.citizenshipFIN',
    defaultMessage: 'Finland',
    description: 'Option for citizenship',
  },
  citizenshipSWE: {
    id: 'rahoitu.fi.citizenshipSWE',
    defaultMessage: 'Sweden',
    description: 'Option for citizenship',
  },
  citizenshipRUS: {
    id: 'rahoitu.fi.citizenshipRUS',
    defaultMessage: 'Russia',
    description: 'Option for citizenship',
  },
  citizenshipEST: {
    id: 'rahoitu.fi.citizenshipEST',
    defaultMessage: 'Estonia',
    description: 'Option for citizenship',
  },
  citizenshipOther: {
    id: 'rahoitu.fi.citizenshipOther',
    defaultMessage: 'Other',
    description: 'Option for citizenship',
  },
  moveInDateRequiredYear: {
    id: 'rahoitu.fi.moveInDateRequiredYear',
    defaultMessage: 'Please enter the year you moved to your current home',
    description: 'Asking customer when they moved to their current home',
  },
  coAppMoveInDateYear: {
    id: 'rahoitu.fi.coAppMoveInDateYear',
    defaultMessage: 'Please enter the year your co-applicant moved to their current home',
    description: 'Asking customer when their co-applicant moved to their current home',
  },
  moveInDateOnlyNumbers: {
    id: 'rahoitu.fi.moveInDateOnlyNumbers',
    defaultMessage: 'Please enter the year in digits (e.g. 2010).',
    description: 'Error message informing the applicant that they may only use digits when filling in which year they moved to Finland',
  },
  moveInDateFourDigits: {
    id: 'rahoitu.fi.moveInDateFourDigits',
    defaultMessage: 'Please write the year in 4 digits, e.g. 2010.',
    description: 'Error message informing the applicant that the format for "year they moved to Finland" should be filled in with four digits',
  },
  moveInDateTooFarBack: {
    id: 'rahoitu.fi.moveInDateTooFarBack',
    defaultMessage: 'Enter the year you moved to your current home (e.g. 2010).',
    description: 'Error message informing the applicant that the year they moved to their current home is set to far back in time',
  },
  coApplicantMoveInDateTooFarBack: {
    id: 'rahoitu.fi.coApplicant.coApplicantMoveInDateTooFarBack',
    defaultMessage: 'Enter the year they moved to their current home (e.g. 2010).',
    description: 'Error message informing the coapplicant moved to their current home is to far back in time',
  },
  moveInDateInTheFuture: {
    id: 'rahoitu.fi.moveInDateInTheFuture',
    defaultMessage: 'Your move date cannot be in the future',
    description: 'Error message informing the applicant that their "move to Finland date" cannot be set to a date in the future',
  },
  coAppMoveInDateInTheFuture: {
    id: 'rahoitu.fi.coAppMoveInDateInTheFuture',
    defaultMessage: 'Their move date cannot be in the future.',
    description: 'Error message informing the coapplicant that their "move to Finland date" cannot be set to a date in the future',
  },
  inFICountrySinceLabel: {
    id: 'rahoitu.fi.inFICountrySinceLabel',
    defaultMessage: 'What year did you move to Finland?',
    description: 'Asking customer how long they have been in fi',
  },
  inFICountrySincePlaceholder: {
    id: 'rahoitu.fi.inFICountrySincePlaceholder',
    defaultMessage: 'In country since (year)',
    description: 'Asking customer how long they have been in fi - placeholder',
  },
  inFICountrySinceRequiredYear: {
    id: 'rahoitu.fi.inFICountrySinceRequiredYear',
    defaultMessage: 'Please enter the year you moved to Finland',
    description: 'Asking customer when they moved to Finland',
  },
  inFICountrySinceOnlyNumbers: {
    id: 'rahoitu.fi.inFICountrySinceOnlyNumbers',
    defaultMessage: 'Please enter the year in digits (e.g. 2010).',
    description: 'Error message informing the applicant that they may only use digits when filling in which year they moved to Finland',
  },
  inFICountrySinceFourDigits: {
    id: 'rahoitu.fi.inFICountrySinceFourDigits',
    defaultMessage: 'Please write the year in 4 digits, e.g. 2010.',
    description: 'Error message informing the applicant that the format for "year they moved to Finland" should be filled in with four digits',
  },
  inFICountrySinceTooFarBack: {
    id: 'rahoitu.fi.inFICountrySinceTooFarBack',
    defaultMessage: 'Enter the year you moved to Finland (e.g. 2010).',
    description: 'Error message informing the applicant that the year they moved to Finalnd is set to far back in time',
  },
  coApplicantInFICountrySinceTooFarBack: {
    id: 'rahoitu.fi.coApplicant.coApplicantInFICountrySinceTooFarBack',
    defaultMessage: 'Enter the year they moved to Finland (e.g. 2010).',
    description: 'Error message informing the coapplicant moved to Finland is to far back in time',
  },
  inFICountrySinceInTheFuture: {
    id: 'rahoitu.fi.inFICountrySinceInTheFuture',
    defaultMessage: 'Your move date cannot be in the future',
    description: 'Error message informing the applicant that their "move to Finland date" cannot be set to a date in the future',
  },
  coAppInFICountrySinceRequiredYear: {
    id: 'rahoitu.fi.coAppInFICountrySinceRequiredYear',
    defaultMessage: 'Please enter the year your co-applicant moved to Finland',
    description: 'Asking customer when their co-applicant moved to Finland',
  },
  coAppInFICountrySinceInTheFuture: {
    id: 'rahoitu.fi.coAppInFICountrySinceInTheFuture',
    defaultMessage: 'Their move date cannot be in the future.',
    description: 'Error message informing the coapplicant that their "move to Finland date" cannot be set to a date in the future',
  },
  inFICountrySincePlaceholderMonth: {
    id: 'rahoitu.fi.inFICountrySincePlaceholderMonth',
    defaultMessage: 'In country since (month)',
    description: 'Asking customer how long they have been in fi - placeholder',
  },
  inFICountrySinceLabelMonth: {
    id: 'rahoitu.fi.inFICountrySinceLabelMonth',
    defaultMessage: 'Month',
    description: 'Asking customer how long they have been in fi - placeholder',
  },
  inFICountrySinceRequiredMonth: {
    id: 'rahoitu.fi.inFICountrySinceRequiredMonth',
    defaultMessage: 'Please enter the month you moved to Finland',
    description: 'Error message informing the applicant that they must pass in which month they moved to Finland',
  },
  inFICountrySinceTimeInTheFutureMonth: {
    id: 'rahoitu.fi.inFICountrySinceTimeInTheFutureMonth',
    defaultMessage: 'The move date cannot be in the future',
    description: 'Error message informing the start date is in the future',
  },
  coAppInFICountrySinceRequiredMonth: {
    id: 'rahoitu.fi.coApplicant.coAppInFICountrySinceRequiredMonth',
    defaultMessage: 'Please enter the month your co-applicant moved to Finland',
    description: 'Error message informing the coapplicant that they must pass in which month they moved to Finland',
  },
  coApplicantInFICountrySinceLabel: {
    id: 'rahoitu.fi.coApplicantInFICountrySinceLabel',
    defaultMessage: 'What year did they move to Finland?',
    description: 'Asking customer how long the coapplicant has been in fi',
  },
  coApplicantInFICountrySincePlaceholder: {
    id: 'rahoitu.fi.coApplicantInFICountrySincePlaceholder',
    defaultMessage: 'E.g 2002',
    description: 'Asking customer how long the coapplicant has been in fi - placeholder',
  },
  coApplicantInFICountrySincePlaceholderMonth: {
    id: 'rahoitu.fi.coApplicantInFICountrySincePlaceholderMonth',
    defaultMessage: 'E.g 2002',
    description: 'Asking customer how long the coapplicant has been in fi - placeholder',
  },
  coApplicantInFICountrySinceLabelMonth: {
    id: 'rahoitu.fi.coApplicantInFICountrySinceLabelMonth',
    defaultMessage: 'Month',
    description: 'Asking customer how long the coapplicant has been in fi - placeholder',
  },
  moveInDateLabel: {
    id: 'rahoitu.fi.moveInDateLabel',
    defaultMessage: 'When did you move to your address? (year)',
    description: 'Asking customer how long they have lived in on this address',
  },
  moveInDateLabelCoApplicant: {
    id: 'rahoitu.fi.moveInDateLabelCoApplicant',
    defaultMessage: 'When did your co-applicant move to their address? (year)',
    description: 'Asking customer how long they have lived in on this address',
  },
  moveInDatePlaceholder: {
    id: 'rahoitu.fi.moveInDatePlaceholder',
    defaultMessage: 'E.g 2002',
    description: 'Asking customer how long they have lived in on this address',
  },
  moveInDatePlaceholderCoApplicant: {
    id: 'rahoitu.fi.moveInDatePlaceholderCoApplicant',
    defaultMessage: 'E.g 2002',
    description: 'Asking customer how long they have lived in on this address',
  },
  bankAccountLabel: {
    id: 'rahoitu.fi.bankAccountLabel',
    defaultMessage: 'Account number (IBAN)',
    description: 'Label for bank account field',
  },
  bankAccountPlaceholder: {
    id: 'rahoitu.fi.bankAccountPlaceholder',
    defaultMessage: 'Bank account (IBAN)',
    description: 'Placeholder for bank account field',
  },
  educationLabel: {
    id: 'rahoitu.fi.educationLabel',
    defaultMessage: 'Education',
    description: 'Label for education section',
  },
  educationTypePrimarySchool: {
    id: 'rahoitu.fi.educationTypePrimarySchool',
    defaultMessage: 'Primary school',
    description: 'education type - primary school',
  },
  educationTypeHighSchool: {
    id: 'rahoitu.fi.educationTypeHighSchool',
    defaultMessage: 'High school',
    description: 'education type - high school',
  },
  educationTypeBachelor: {
    id: 'rahoitu.fi.educationTypeBachelor',
    defaultMessage: 'Bachelor',
    description: 'education type - bachelor',
  },
  educationTypeCollege: {
    id: 'rahoitu.fi.educationTypeCollege',
    defaultMessage: 'College',
    description: 'education type - college',
  },
  educationTypeMasters: {
    id: 'rahoitu.fi.educationTypeMasters',
    defaultMessage: 'Masters',
    description: 'education type - masters degree',
  },
  coApplicantEducationLabel: {
    id: 'rahoitu.fi.coApplicantEducationLabel',
    defaultMessage: 'Co-applicant’s Education',
    description: 'Label for coapplicant education section',
  },
  occupationLabel: {
    id: 'rahoitu.fi.occupationLabel',
    defaultMessage: 'Occupation',
    description: 'occupation label',
  },
  workPositionLabel: {
    id: 'rahoitu.fi.workPositionLabel',
    defaultMessage: 'Work Position',
    description: 'occupation label',
  },
  occupationPlaceholder: {
    id: 'rahoitu.fi.occupationPlaceholder',
    defaultMessage: 'Occupation',
    description: 'occupation placeholder',
  },
  coApplicantOccupationLabel: {
    id: 'rahoitu.fi.coApplicantOccupationLabel',
    defaultMessage: 'Co-applicant’s Occupation',
    description: 'coapplicant occupation label',
  },
  coApplicantOccupationPlaceholder: {
    id: 'rahoitu.fi.coApplicantOccupationPlaceholder',
    defaultMessage: 'Co-applicant’s occupation category',
    description: 'coapplicant occupation placeholder',
  },
  occupationCategoryLabel: {
    id: 'rahoitu.fi.occupationCategoryLabel',
    defaultMessage: 'Occupation category',
    description: 'Occupation category - label',
  },
  coApplicantOccupationTitleLabel: {
    id: 'rahoitu.fi.coApplicantOccupationTitleLabel',
    defaultMessage: 'Co-applicant´s occupation title',
    description: 'Occupation title - label',
  },
  occupationTitleLabel: {
    id: 'rahoitu.fi.occupationTitleLabel',
    defaultMessage: 'Occupation title',
    description: 'Occupation title - label',
  },
  occupationTitlePlaceholder: {
    id: 'rahoitu.fi.occupationTitlePlaceholder',
    defaultMessage: 'E.g project manager',
    description: 'Occupation title - placeholder',
  },
  occupationCategoryEntrepreneur: {
    id: 'rahoitu.fi.occupationCategoryEntrepreneur',
    defaultMessage: 'Entrepreneur',
    description: 'Occupation category - Entrepreneur',
  },
  occupationCategorySeniorClerk: {
    id: 'rahoitu.fi.occupationCategorySeniorClerk',
    defaultMessage: 'Senior official',
    description: 'Occupation category - senior official',
  },
  occupationCategoryLowerEmployee: {
    id: 'rahoitu.fi.occupationCategoryLowerEmployee',
    defaultMessage: 'Junior employee',
    description: 'Occupation category - Lower employee',
  },
  occupationCategoryEmployee: {
    id: 'rahoitu.fi.occupationCategoryEmployee',
    defaultMessage: 'Employee',
    description: 'Occupation category - Employee',
  },
  occupationCategoryFarmer: {
    id: 'rahoitu.fi.occupationCategoryFarmer',
    defaultMessage: 'Farmer',
    description: 'Occupation category - Farmer',
  },
  occupationCategoryStudent: {
    id: 'rahoitu.fi.occupationCategoryStudent',
    defaultMessage: 'Student',
    description: 'Occupation category - Student',
  },
  occupationCategoryLeadership: {
    id: 'rahoitu.fi.occupationCategoryLeadership',
    defaultMessage: 'Leadership',
    description: 'Occupation category - Leadership',
  },
  occupationCategoryPensioner: {
    id: 'rahoitu.fi.occupationCategoryPensioner',
    defaultMessage: 'Pensioner',
    description: 'Occupation category - Pensioner',
  },
  studentSinceYearDynamicLabel: {
    id: 'rahoitu.fi.studentSinceYearDynamicLabel',
    defaultMessage: 'Student since (year)',
    description: 'Label for employed since if student',
  },
  selfEmployedEmployerNameDynamicLabel: {
    id: 'rahoitu.fi.selfEmployedEmployerNameDynamicLabel',
    defaultMessage: 'Name of company',
    description: 'Label for employer if self employed',
  },
  selfEmployedSinceYearDynamicLabel: {
    id: 'rahoitu.fi.selfEmployedSinceYearDynamicLabel',
    defaultMessage: 'Self employed since (year)',
    description: 'Label for employed since year if self employed',
  },
  selfEmployedSinceMonthDynamicLabel: {
    id: 'rahoitu.fi.selfEmployedSinceMonthDynamicLabel',
    defaultMessage: 'Self employed since (month)',
    description: 'Label for employed since month if self employed',
  },
  studentSinceMonthDynamicLabel: {
    id: 'rahoitu.fi.studentSinceMonthDynamicLabel',
    defaultMessage: 'Student since (month)',
    description: 'Label for employed since month if student',
  },
  studentSinceDynamicLabel: {
    id: 'rahoitu.fi.studentSinceDynamicLabel',
    defaultMessage: 'Student since (year)',
    description: 'Label for employed since if student',
  },
  occupationCategoryOther: {
    id: 'rahoitu.fi.occupationCategoryOther',
    defaultMessage: 'Other',
    description: 'Occupation category - Other',
  },
  militaryTypeLabel: {
    id: 'rahoitu.fi.militaryTypeLabel',
    defaultMessage: 'Military service',
    description: 'Military Type - Executed',
  },
  militaryTypeExecuted: {
    id: 'rahoitu.fi.militaryTypeExecuted',
    defaultMessage: 'Completed',
    description: 'Military Type - Executed',
  },
  militaryTypeNotCompleted: {
    id: 'rahoitu.fi.militaryTypeNotCompleted',
    defaultMessage: 'Not completed',
    description: 'Military Type - Not Executed',
  },
  militaryTypeReleased: {
    id: 'rahoitu.fi.militaryTypeReleased',
    defaultMessage: 'Released',
    description: 'Military Type - Released',
  },
  militaryTypeNotApplicable: {
    id: 'rahoitu.fi.militaryTypeNotApplicable',
    defaultMessage: 'Not Applicable',
    description: 'Military Type - Not Applicable',
  },
  coApplicantMilitaryTypeLabel: {
    id: 'rahoitu.fi.coApplicantMilitaryTypeLabel',
    defaultMessage: 'Co-applicant’s Military service',
    description: 'Military Type - Executed',
  },
  privateLoansLabel: {
    id: 'rahoitu.fi.privateLoansLabel',
    defaultMessage: 'Do you have consumer loans?',
    description: 'private loans question/label',
  },
  privateLoansAmountLabel: {
    id: 'rahoitu.fi.privateLoansAmountLabel',
    defaultMessage: 'Consumer loan amount (€)',
    description: 'private loans amount',
  },
  privateLoansAmountPlaceholder: {
    id: 'rahoitu.fi.privateLoansAmountPlaceholder',
    defaultMessage: 'Consumer loan amount (€)',
    description: 'private loans amount',
  },
  privateLoansMonthlyCostPlaceholder: {
    id: 'rahoitu.fi.privateLoansMonthlyCostPlaceholder',
    defaultMessage: 'Consumer loan monthly cost (€/mth)',
    description: 'private loans monthly cost',
  },
  privateLoansMonthlyCostLabel: {
    id: 'rahoitu.fi.privateLoansMonthlyCostLabel',
    defaultMessage: 'Consumer loan monthly cost (€/mth)',
    description: 'private loans monthly cost',
  },
  creditLoansLabel: {
    id: 'rahoitu.fi.creditLoansLabel',
    defaultMessage: 'Do you have credit loans? (€)',
    description: 'Asking customer if they have credit loans',
  },
  creditLoansAmountLabel: {
    id: 'rahoitu.fi.creditLoansAmountLabel',
    defaultMessage: 'Credit loan amount (€)',
    description: 'Credit loan amount label',
  },
  creditLoansAmountPlaceholder: {
    id: 'rahoitu.fi.creditLoansAmountPlaceholder',
    defaultMessage: 'Credit loan amount (€)',
    description: 'Credit loan amount placeholder',
  },
  creditLoansMonthlyCostLabel: {
    id: 'rahoitu.fi.creditLoansMonthlyCostLabel',
    defaultMessage: 'Credit loan monthly cost (€/mth)',
    description: 'Credit loan monthly cost label',
  },
  creditLoansMonthlyCostPlaceholder: {
    id: 'rahoitu.fi.creditLoansMonthlyCostPlaceholder',
    defaultMessage: 'Credit loan monthly cost (€/mth)',
    description: 'Credit loan monthly cost placeholder',
  },
  houseLoansLabel: {
    id: 'rahoitu.fi.houseLoansLabel',
    defaultMessage: 'Do you have any house loans?',
    description: 'House loan label/questions',
  },
  houseLoansAmountLabel: {
    id: 'rahoitu.fi.houseLoansAmountLabel',
    defaultMessage: 'House loans amount (€)',
    description: 'House loan amount label',
  },
  houseLoansAmountPlaceholder: {
    id: 'rahoitu.fi.houseLoansAmountPlaceholder',
    defaultMessage: 'House loans amount (€)',
    description: 'House loan amount placeholder',
  },
  houseLoansMonthlyCostLabel: {
    id: 'rahoitu.fi.houseLoansMonthlyCostLabel',
    defaultMessage: 'House loans monthly cost (€/mth)',
    description: 'House loan MonthlyCost label',
  },
  houseLoansMonthlyCostPlaceholder: {
    id: 'rahoitu.fi.houseLoansMonthlyCostPlaceholder',
    defaultMessage: 'House loans monthly cost (€/mth)',
    description: 'House loan MonthlyCost placeholder',
  },
  houseLoansPartLabel: {
    id: 'rahoitu.fi.houseLoansPartLabel',
    defaultMessage: 'How much is shared with your partner?',
    description: 'Asking customer how much of the house loan they pay',
  },
  houseLoansLoanIsOwnedByApplicant: {
    id: 'rahoitu.fi.houseLoansLoanIsOwnedByApplicant',
    defaultMessage: 'I am responsible',
    description: 'Text for applicant is owning the loan',
  },
  houseLoansPartHalf: {
    id: 'rahoitu.fi.houseLoansPartHalf',
    defaultMessage: '50%',
    description: 'Paying 50% of the house loan',
  },
  houseLoansLoanIsSharedWithCoApplicant: {
    id: 'rahoitu.fi.houseLoansLoanIsSharedWithCoApplicant',
    defaultMessage: 'Shared responsibility',
    description: 'Text for the house loan is shared with co applicant',
  },
  carLoansLabel: {
    id: 'rahoitu.fi.carLoansLabel',
    defaultMessage: 'Do you have car loans?',
    description: 'Asking customer if they have car loans',
  },
  carLoansAmountLabel: {
    id: 'rahoitu.fi.carLoansAmountLabel',
    defaultMessage: 'Car loans total amount (€)',
    description: 'Label car loans amount',
  },
  carLoansAmountPlaceholder: {
    id: 'rahoitu.fi.carLoansAmountPlaceholder',
    defaultMessage: 'Car loans total amount (€)',
    description: 'Placeholder car loans amount',
  },
  carLoansMonthlyCostLabel: {
    id: 'rahoitu.fi.carLoansMonthlyCostLabel',
    defaultMessage: 'Car loans monthly cost (€/mth)',
    description: 'Label car loans monthly cost',
  },
  carLoansMonthlyCostPlaceholder: {
    id: 'rahoitu.fi.carLoansMonthlyCostPlaceholder',
    defaultMessage: 'Car loans monthly cost (€/mth)',
    description: 'Placeholder car loans monthly cost',
  },
  studentLoansLabel: {
    id: 'rahoitu.fi.studentLoansLabel',
    defaultMessage: 'Do you have student loans? (€)',
    description: 'Asking customer if they have student loans',
  },
  studentLoansAmountLabel: {
    id: 'rahoitu.fi.studentLoansAmountLabel',
    defaultMessage: 'Student loans total amount (€)',
    description: 'Label student loans amount',
  },
  studentLoansAmountPlaceholder: {
    id: 'rahoitu.fi.studentLoansAmountPlaceholder',
    defaultMessage: 'Student loans total amount (€)',
    description: 'Placeholder student loans amount',
  },
  studentLoansMonthlyCostLabel: {
    id: 'rahoitu.fi.studentLoansMonthlyCostLabel',
    defaultMessage: 'Student loans monthly cost (€/mth)',
    description: 'Label student loans monthly cost',
  },
  studentLoansMonthlyCostPlaceholder: {
    id: 'rahoitu.fi.studentLoansMonthlyCostPlaceholder',
    defaultMessage: 'Student loans monthly cost (€/mth)',
    description: 'Placeholder student loans monthly cost',
  },
  isPepLabel: {
    id: 'rahoitu.fi.isPepLabel',
    defaultMessage: 'Are you, or anyone close to you, a politically exposed person?',
    description: 'Asking customer if they are politically exposed',
  },
  coApplicantPrivateLoansLabel: {
    id: 'rahoitu.fi.coApplicantPrivateLoansLabel',
    defaultMessage: 'Does your co-applicant have consumer loans?',
    description: 'private loans question/label',
  },
  coApplicantPrivateLoansAmountLabel: {
    id: 'rahoitu.fi.coApplicantPrivateLoansAmountLabel',
    defaultMessage: 'Consumer loan amount (€)',
    description: 'private loans amount',
  },
  coApplicantPrivateLoansAmountPlaceholder: {
    id: 'rahoitu.fi.coApplicantPrivateLoansAmountPlaceholder',
    defaultMessage: 'Consumer loan amount (€)',
    description: 'private loans amount',
  },
  coApplicantPrivateLoansMonthlyCostPlaceholder: {
    id: 'rahoitu.fi.coApplicantPrivateLoansMonthlyCostPlaceholder',
    defaultMessage: 'Consumer loan monthly cost (€/mth)',
    description: 'private loans monthly cost',
  },
  coApplicantPrivateLoansMonthlyCostLabel: {
    id: 'rahoitu.fi.coApplicantPrivateLoansMonthlyCostLabel',
    defaultMessage: 'Consumer loan monthly cost (€/mth)',
    description: 'private loans monthly cost',
  },
  coApplicantCreditLoansLabel: {
    id: 'rahoitu.fi.coApplicantCreditLoansLabel',
    defaultMessage: 'Does your co-applicant have credit loans?',
    description: 'Asking customer if they have credit loans',
  },
  coApplicantCreditLoansAmountLabel: {
    id: 'rahoitu.fi.coApplicantCreditLoansAmountLabel',
    defaultMessage: 'Credit loan amount (€)',
    description: 'Credit loan amount label',
  },
  coApplicantCreditLoansAmountPlaceholder: {
    id: 'rahoitu.fi.coApplicantCreditLoansAmountPlaceholder',
    defaultMessage: 'Credit loan amount (€)',
    description: 'Credit loan amount placeholder',
  },
  coApplicantCreditLoansMonthlyCostLabel: {
    id: 'rahoitu.fi.coApplicantCreditLoansMonthlyCostLabel',
    defaultMessage: 'Credit loan monthly cost (€/mth)',
    description: 'Credit loan monthly cost label',
  },
  coApplicantCreditLoansMonthlyCostPlaceholder: {
    id: 'rahoitu.fi.coApplicantCreditLoansMonthlyCostPlaceholder',
    defaultMessage: 'Credit loan monthly cost (€/mth)',
    description: 'Credit loan monthly cost placeholder',
  },
  coApplicantHouseLoansLabel: {
    id: 'rahoitu.fi.coApplicantHouseLoansLabel',
    defaultMessage: 'Does your co-applicant have any house loans?',
    description: 'House loan label/questions',
  },
  coApplicantHuseLoansAmountLabel: {
    id: 'rahoitu.fi.coApplicantHouseLoansAmountLabel',
    defaultMessage: 'House loans amount (€)',
    description: 'House loan amount label',
  },
  coApplicantHouseLoansAmountPlaceholder: {
    id: 'rahoitu.fi.coApplicantHouseLoansAmountPlaceholder',
    defaultMessage: 'House loans amount (€)',
    description: 'House loan amount placeholder',
  },
  coApplicantHouseLoansMonthlyCostLabel: {
    id: 'rahoitu.fi.coApplicantHouseLoansMonthlyCostLabel',
    defaultMessage: 'House loans monthly cost (€/mth)',
    description: 'House loan MonthlyCost label',
  },
  coApplicantHouseLoansMonthlyCostPlaceholder: {
    id: 'rahoitu.fi.coApplicantHouseLoansMonthlyCostPlaceholder',
    defaultMessage: 'House loans monthly cost (€/mth)',
    description: 'House loan MonthlyCost placeholder',
  },
  coApplicantHouseLoansPartLabel: {
    id: 'rahoitu.fi.coApplicantHouseLoansPartLabel',
    defaultMessage: 'How much is shared with their partner?',
    description: 'Asking customer how much of the house loan they pay',
  },
  coApplicantCarLoansLabel: {
    id: 'rahoitu.fi.coApplicantCarLoansLabel',
    defaultMessage: 'Does your co-applicant have car loans?',
    description: 'Asking customer if they have car loans',
  },
  coApplicantCarLoansAmountLabel: {
    id: 'rahoitu.fi.coApplicantCarLoansAmountLabel',
    defaultMessage: 'Car loans amount (€)',
    description: 'Label car loans amount',
  },
  coApplicantCarLoansAmountPlaceholder: {
    id: 'rahoitu.fi.coApplicantCarLoansAmountPlaceholder',
    defaultMessage: 'Car loans amount (€)',
    description: 'Placeholder car loans amount',
  },
  coApplicantCarLoansMonthlyCostLabel: {
    id: 'rahoitu.fi.coApplicantCarLoansMonthlyCostLabel',
    defaultMessage: 'Car loans monthly cost (€/mth)',
    description: 'Label car loans monthly cost',
  },
  coApplicantCarLoansMonthlyCostPlaceholder: {
    id: 'rahoitu.fi.coApplicantCarLoansMonthlyCostPlaceholder',
    defaultMessage: 'Car loans monthly cost (€/mth)',
    description: 'Placeholder car loans monthly cost',
  },
  coApplicantStudentLoansLabel: {
    id: 'rahoitu.fi.coApplicantStudentLoansLabel',
    defaultMessage: 'Does your co-applicant have student loans?',
    description: 'Asking customer if they have student loans',
  },
  coApplicantStudentLoansAmountLabel: {
    id: 'rahoitu.fi.coApplicantStudentLoansAmountLabel',
    defaultMessage: 'Student loans amount (€)',
    description: 'Label student loans amount',
  },
  coApplicantStudentLoansAmountPlaceholder: {
    id: 'rahoitu.fi.coApplicantStudentLoansAmountPlaceholder',
    defaultMessage: 'Student loans amount (€)',
    description: 'Placeholder student loans amount',
  },
  coApplicantStudentLoansMonthlyCostLabel: {
    id: 'rahoitu.fi.coApplicantStudentLoansMonthlyCostLabel',
    defaultMessage: 'Student loans monthly cost (€/mth)',
    description: 'Label student loans monthly cost',
  },
  coApplicantStudentLoansMonthlyCostPlaceholder: {
    id: 'rahoitu.fi.coApplicantStudentLoansMonthlyCostPlaceholder',
    defaultMessage: 'Student loans monthly cost (€/mth)',
    description: 'Placeholder student loans monthly cost',
  },
  isCoApplicantPepLabel: {
    id: 'rahoitu.fi.isCoApplicantPepLabel',
    defaultMessage: 'Is your co-applicant, or anyone close to them, a politically exposed person?',
    description: 'Asking customer if the coApplicant is a politically exposed person',
  },
  creditCardLabel: {
    id: 'rahoitu.fi.creditCardLabel',
    defaultMessage: 'Do you have a credit card?',
    description: 'Asking customer if they have credit cards',
  },
  coApplicantCreditCardLabel: {
    id: 'rahoitu.fi.coApplicantCreditCardLabel',
    defaultMessage: 'Does your co-applicant have a credit card?',
    description: 'Asking customer if the coApplicant have credit cards',
  },
  coApplicantOtherIncomeLabel: {
    id: 'rahoitu.fi.coApplicantOtherIncomeLabel',
    defaultMessage: 'Does your co-applicant have any other income?',
    description: 'Asking if coApplicant has other income',
  },
  coApplicantGrossOtherIncomeLabel: {
    id: 'rahoitu.fi.coApplicantGrossOtherIncomeLabel',
    defaultMessage: "Co-applicant's other income before tax (€/mth)",
    description: 'Asking customer what their other income before tax is',
  },
  coApplicantGrossOtherIncomePlaceholder: {
    id: 'rahoitu.fi.coApplicantGrossOtherIncomePlaceholder',
    defaultMessage: 'X XXX',
    description: 'Asking customer what their other income before tax is',
  },
  coApplicantNetOtherIncomeLabel: {
    id: 'rahoitu.fi.coApplicantNetOtherIncomeLabel',
    defaultMessage: "Co-applicant's other income after tax (€/mth)",
    description: 'Asking customer what their other income after tax is',
  },
  coApplicantNetOtherIncomePlaceholder: {
    id: 'rahoitu.fi.coApplicantNetOtherIncomePlaceholder',
    defaultMessage: 'X XXX',
    description: 'Asking customer what their other income after tax is',
  },
  coApplicantOtherIncomeSourceLabel: {
    id: 'rahoitu.fi.coApplicantOtherIncomeSourceLabel',
    defaultMessage: "Co-applicant's other income source",
    description: 'Asking customer what their other income after tax is',
  },
  coApplicantOtherIncomeSourcePlaceholder: {
    id: 'rahoitu.fi.coApplicantOtherIncomeSourcePlaceholder',
    defaultMessage: 'E.g second job',
    description: 'Asking customer what their other income after tax is',
  },
  grossOtherIncomeLabel: {
    id: 'rahoitu.fi.grossOtherIncomeLabel',
    defaultMessage: 'Other income before tax (€/mth)',
    description: 'Asking customer what their other income before tax is',
  },
  grossOtherIncomePlaceholder: {
    id: 'rahoitu.fi.grossOtherIncomePlaceholder',
    defaultMessage: 'X XXX',
    description: 'Asking customer what their other income before tax is',
  },
  netOtherIncomeLabel: {
    id: 'rahoitu.fi.netOtherIncomeLabel',
    defaultMessage: 'Other income after tax (€/mth)',
    description: 'Asking customer what their other income after tax is',
  },
  netOtherIncomePlaceholder: {
    id: 'rahoitu.fi.netOtherIncomePlaceholder',
    defaultMessage: 'X XXX',
    description: 'Asking customer what their other income after tax is',
  },
  otherIncomeSourceLabel: {
    id: 'rahoitu.fi.otherIncomeSourceLabel',
    defaultMessage: 'Other income source',
    description: 'Asking customer what their other income after tax is',
  },
  otherIncomeSourcePlaceholder: {
    id: 'rahoitu.fi.otherIncomeSourcePlaceholder',
    defaultMessage: 'E.g second job',
    description: 'Asking customer what their other income after tax is',
  },
  businessIdLabel: {
    id: 'rahoitu.fi.businessIdLabel',
    defaultMessage: 'Business ID',
    description: 'Label for Business ID field',
  },
  coApplicantBusinessIdLabel: {
    id: 'rahoitu.fi.coApplicantBusinessIdLabel',
    defaultMessage: 'Business ID',
    description: 'Label for coApplicant field Business ID',
  },
  businessIdPlaceholder: {
    id: 'rahoitu.fi.businessIdPlaceholder',
    defaultMessage: '1234567-8',
    description: 'Placeholder for Business ID field',
  },
  coApplicantBusinessIdPlaceholder: {
    id: 'rahoitu.fi.coApplicantBusinessIdPlaceholder',
    defaultMessage: '1234567-8',
    description: 'Placeholder for coApplicant field Business ID',
  },
  civilStatusDivorced: {
    id: 'rahoitu.fi.civilStatusDivorced',
    defaultMessage: 'Divorced',
    description: 'Button text for civil status divorced',
  },
  civilStatusWidow: {
    id: 'rahoitu.fi.civilStatusWidow',
    defaultMessage: 'Widow',
    description: 'Button text for civil status widow',
  },
  acceptCreditCheck: {
    id: 'rahoitu.fi.acceptCreditCheck',
    defaultMessage: 'I give my consent to Sambla obtaining a credit report from Suomen Asiakastieto Oy:n and to the transfer of data to credit providers/banks in accordance with the survey system maintained by Asiakastieto Oy.',
    description: 'Credit check acceptance message',
  },
  businessIDRequired: {
    id: 'rahoitu.fi.businessIdIsEmpty',
    defaultMessage: 'Please enter your Business ID',
    description: '',
  },
  invalidBusinessIDFormat: {
    id: 'rahoitu.fi.businessIdWrongFormat',
    defaultMessage: 'Please enter in format 123456-7',
    description: 'Error message informing the applicant that the business ID number is wrongly formatted',
  },
  loanCalculatorInterest: {
    id: 'rahoitu.fi.loanCalculatorInterest',
    defaultMessage: 'Effective interest rate',
    description: 'shows the interest rate in calculator',
  },
  approximateMonthlyCostMobile: {
    id: 'rahoitu.fi.approximateMonthlyCostMobile',
    defaultMessage: 'Approximate Cost {monthlyCost} EUR/month',
    description: 'Shows the apprx. monthly cost on mobile',
  },
  duplicateApplicationErrorMessage: {
    id: 'rahoitu.fi.submitError.duplicateApplication',
    defaultMessage: 'It looks like you already have a valid application. You can find it by logging into your <a>OmaTilili</a>. If you want to make changes to the application, please contact our customer service on',
    description: 'Text for the error message that is displayed to the customer when they already have a active application.',
  },
  errorMessageTitle: {
    id: 'rahoitu.fi.submitError.title',
    defaultMessage: 'Wops!',
    description: 'Title for the error that is displayed on submit.',
  },
  backButton: {
    id: 'rahoitu.fi.backButton',
    defaultMessage: 'Back',
    description: 'Back Button',
  },
  otherLivingCostLabel: {
    id: 'rahoitu.fi.otherLivingCost.label',
    defaultMessage: 'Other living costs (€/mth)',
    description: 'otherLivingCost Label',
  },
  otherLivingCostTooltip: {
    id: 'rahoitu.fi.otherLivingCostTooltip',
    defaultMessage: 'Please enter the monthly cost connected to your other living costs besides housing.',
    description: 'Tooltip-text för other living costs i formulär.',
  },
  coApplicantOtherLivingCostsLabel: {
    id: 'rahoitu.fi.coApplicantOtherLivingCostsLabel',
    defaultMessage: "Co-applicant's other living costs (€/mth)",
    description: 'Label-text för other living costs i formulär.',
  },
  coApplicantOtherLivingCostsTooltip: {
    id: 'rahoitu.fi.coApplicantOtherLivingCostsTooltip',
    defaultMessage: 'Please enter the monthly cost connected to your other living costs besides housing.',
    description: 'Tooltip for coApplicant other living cost field',
  },
  pCRText: {
    id: 'rahoitu.fi.PCRText',
    defaultMessage: 'I am aware that the creditor can collect and process my credit and income information obtained from the positive credit register maintained by the Income Registry Unit of the Tax Administration. The creditor can use this information to process the loan application, assess creditworthiness and check that the information is up-to-date. The creditor can also report my credit information to the positive credit register.',
    description: 'Due to the implementation of PCR in FI we will have to “warn” customer about that.',
  },
  debtBreakdownLabel: {
    id: 'rahoitu.fi.debtBreakdownLabel',
    defaultMessage: 'Current loans',
    description: 'Form header for current loans section',
  },
  bankAccountHeadline: {
    id: 'rahoitu.fi.bankAccountHeadline',
    defaultMessage: 'Please enter your bank account number',
    description: 'tittle for bank account field',
  },
});

export default messages;
